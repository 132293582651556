import { AfterViewInit, Component, Input } from "@angular/core";
import { DatastoreService } from "../services/datastore.service";
declare let L;
declare var GreyscaleMap: any;

@Component({
  selector: "app-leaflet-map",
  templateUrl: "./leaflet-map.component.html",
  styleUrls: ["./leaflet-map.component.scss"],
})
export class LeafletMapComponent implements AfterViewInit {
  marker;
  lat;
  long;
  map;
  @Input() confirmationPage: boolean;
  @Input() num: any;


  constructor(private datastoreService: DatastoreService) { }

  ngAfterViewInit() {

    // set map coordinates
    this.lat = this.datastoreService.latitude;
    this.long = this.datastoreService.longitude;

    this.map = L.map(`map${this.num}`).setView(
      [this.lat, this.long],
      15
    );

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution: `&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors | <a href="https://www.bookinglab.co.uk/">Bookinglab</a>`,
    }).addTo(this.map);

    this.marker = L.marker([this.lat, this.long]);
    this.map.addLayer(this.marker);

    // updates
    this.datastoreService.mapUpdate.subscribe(() => {
      this.map.removeLayer(this.marker)
      this.marker = L.marker([this.datastoreService.latitude, this.datastoreService.longitude]);
      this.map.addLayer(this.marker);

      this.map.flyTo([this.datastoreService.latitude, this.datastoreService.longitude], 15, {
        animate: true,
        duration: 0.6,
      });
    })
  }

  renderMapLocation(lat, long) {
    // console.log(this.marker)
    // console.log(this.map)
    //  this.marker.setLatLng([lat, long]).update();

    // const map.flyTo([lat, long], 15, {
    //   animate: true,
    //   duration: 0.6,
    // }); 
  }
}
