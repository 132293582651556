import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from 'src/app/app.config';

@Injectable({
  providedIn: 'root'
})
export class BasketService {
  private core = AppConfig.settings.core;
  private apiServer = AppConfig.settings.apiServer;
  currentBasket;
  purchase;

  constructor(
    private http: HttpClient
  ) { }

  async createBasket(selectedLocation) {
    const company = selectedLocation;
    this.currentBasket = await company.$post('baskets', { company_id: company.id });
    return this.currentBasket;
  }

  async getCurrentBasket(selectedLocation) {
    const company = selectedLocation;
    const baseBasketResource = await company.$get('baskets', { company_id: company.id });
    const baskets = await baseBasketResource.$get('baskets');
    this.currentBasket = baskets[baskets.length - 1];
    return baskets[baskets.length - 1];
  }

  async deleteAllBaskets(selectedLocation) {
    const company = selectedLocation;
    if (!company.$has('baskets')) {
      return null;
    }

    const baskets = await this.checkForExistingBaskets(selectedLocation);

    if (baskets.length >= 1) {
      for (const basket of baskets) {
        new Promise(() => {
          company.$del('baskets', { basket_id: basket.id }).then(() => {
            Promise.resolve();
          });
        });
      }
    }
  }

  async checkForExistingBaskets(selectedLocation) {
    const company = selectedLocation;
    if (company.$has('baskets')) {
      const baseBasketResource = await company.$get('baskets', { company_id: company.id });
      const baskets = await baseBasketResource.$get('baskets');
      return baskets;
    }
  }

  async addItem(selectedLocation, service_id, duration, start, questions, resource?) {
    const company = selectedLocation;
    if (company.$has('baskets')) {

      try {
        const item = await this.currentBasket.$post('service_items', {}, {
          service_id,
          duration,
          start,
          company_id: company.id,
          basket_id: this.currentBasket.id,
          questions,
          resource_id: resource
        });
        return item;
      } catch (error) {
        const nr:any = window['newrelic'];
        if (nr) {
          nr.addPageAction('ELWAError', {client: 'East London Waste', host: AppConfig.settings.apiServer, errorMessage: JSON.stringify(error), errorType: 'SERVICE_ITEM'});

        }
        return 'failed';
      }
    }
  }

  checkoutBasket(selectedLocation, client, notifications) {
    const company = selectedLocation;
    if (company.$has('baskets')) {

      try {
        return this.currentBasket.$post('checkout', {}, {
          client,
          basket_id: this.currentBasket.id,
          no_notifications: notifications
        }).then((res) => {
          this.purchase = res;
          return res;
        });
      } catch (error) {
        return 'failed';
      }
    }
  }

  async getPurchase() {
    return this.purchase;
  }

  async addAttachement(purchase, bookings, formData) {
    const headers: HttpHeaders = new HttpHeaders({
      'App-Id': this.core.app_id,
      'App-Key': this.core.app_key
    });
    const apiUrl = `${this.apiServer.api_url}/api/${this.apiServer.version}/purchases/${purchase.long_id}/bookings/${bookings[0].id}/attach`;
    const attach = await this.http.post(apiUrl, formData, { headers }).toPromise();
    return attach;
  }

  async delete(company, basket) {
    if (!company.$has('baskets')) return null;
    return company.$del('baskets', { basket_id: basket.id });
  }
}
