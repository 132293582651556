import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { DepartmentService } from './department.service';


@Injectable({
  providedIn: 'root'
})
export class ResourcesService {
  selectedResource;
  resources;

  constructor() { }

  async getResources(department) {
    try {
      this.resources = await department.$getResources();
      return this.resources;
    } catch (error) {
      return [];
    }

  }

  async set(resource) {
    this.selectedResource = resource;
  }

  async get() {
    return this.selectedResource;
  }
}
