import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { DepartmentService } from "src/app/services/jrni/department.service";
import { ServicesService } from "src/app/services/jrni/services.service";
import { AvailabilityService } from "src/app/services/jrni/availability.service";
import { BasketService } from "src/app/services/jrni/basket.service";
import { MemberService } from "src/app/services/jrni/member.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { DatastoreService } from "src/app/services/datastore.service";
import { AppConfig } from "src/app/app.config";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AlertService } from "src/app/_alert";
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from "src/app/services/jrni/api.service";
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ResourcesService } from "src/app/services/jrni/resources.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-amend',
  templateUrl: './amend.component.html',
  styleUrls: ['./amend.component.scss']
})
export class AmendComponent implements OnInit {

  pageTitle: string;
  purchaseId: string = '';
  url:any = `https://customer.jrni.com/?client=abbott-uk-adc&environment=trainme.freestyle.abbottSTAGING&purchase=REPLACE#/amend/view`;

  constructor(
    private router: Router,
    private departmentService: DepartmentService,
    private servicesService: ServicesService,
    private availabilityService: AvailabilityService,
    private basketService: BasketService,
    private memberService: MemberService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private datastoreService: DatastoreService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private apiService: ApiService,
    private resourcesService: ResourcesService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.pageTitle = 'Amend or cancel booking';
    const routeParams = this.route.snapshot.paramMap;
    const purchaseId = routeParams.get('purchaseId').trim();
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.url.replace('REPLACE', purchaseId));
    this.spinner.hide();
  }

}
