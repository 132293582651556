import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { AppConfig } from "src/app/app.config";
import { DatastoreService } from "src/app/services/datastore.service";
import { DobValidatorService } from "src/app/services/dob-validator.service";
import { ApiService } from "src/app/services/jrni/api.service";
import { AvailabilityService } from "src/app/services/jrni/availability.service";
import { BasketService } from "src/app/services/jrni/basket.service";
import { DepartmentService } from "src/app/services/jrni/department.service";
import { MemberService } from "src/app/services/jrni/member.service";
import { ServicesService } from "src/app/services/jrni/services.service";
import { AlertService } from "src/app/_alert";
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';

@Component({
  selector: "app-booking-details",
  templateUrl: "./booking-details.component.html",
  styleUrls: ["./booking-details.component.scss"],
})
export class BookingDetailsComponent implements OnInit {
  parentDepartment;
  selectedDepartment;
  selectedService;
  pageTitle: string;
  dataPolicy: string;
  dataPolicyTop: string;
  declaration_question_override: string;
  bookingDetailsPageText: string;
  bookingQuestions = [];
  submitted: boolean;
  selectedLocation;
  requiresFullRegistration = false;
  organisationQuestions = [];
  today = new Date();
  selectedFile: string;
  subtmitButtonDisabled: any;
  bookingQuestionsConfig = AppConfig.settings.bookingQuestions;
  addresses = [];
  addressFoundBefore = false;
  member;
  slot;
  banned = false;
  customerQuestions;
  unavailableTimes = [];
  // https://regexr.com/3pp3r <- Postcode validator
  postcodeValidator = '^(([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2}))$'
  showingAddresses = false;
  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;
  selectedAddressId;
  custodianCodes = [5060, 5480, 5750, 5780];
  isInLocalArea: boolean = false;
  showWasteWarning: boolean = false;
  wasteLimit: any = {};
  wasteUnits: string = "litres"
  last5Value: any;
  countFor5: number = -1;
  underAgeIssue: boolean = false;

  constructor(
    private router: Router,
    private servicesService: ServicesService,
    private departmentService: DepartmentService,
    private formBuilder: FormBuilder,
    private basketservice: BasketService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private memberService: MemberService,
    public datastoreService: DatastoreService,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private availabilityService: AvailabilityService
  ) { }


  emailValidators = [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i)];
  phoneValidators = [Validators.required, Validators.minLength(5), Validators.maxLength(11), Validators.pattern(/^[0-9]*$/)];


  // Initialise the customer form
  customerDetailsForm: FormGroup = new FormGroup({
    first_name: new FormControl("", Validators.required),
    last_name: new FormControl("", Validators.required),
    email_address: new FormControl("", this.emailValidators),
    mobile_number: new FormControl("", this.phoneValidators),
    // postcode: new FormControl("", [Validators.required, Validators.pattern(this.postcodeValidator)]),
    data_policy: new FormControl(false, Validators.requiredTrue)
  }, {
    // validators: this.matchingEmailsValidator('email_address', '362')
  });

  addressEnabled = AppConfig.settings.addressApi.enabled;

  matchingEmailsValidator(emailKey: string, confirmEmailKey: string): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } => {

      let email = group.controls[emailKey];
      let confirmEmail = group.controls[confirmEmailKey];

      // If the email and confirm email inputs have data
      if (email.value.length > 0 && confirmEmail.value.length > 0) {
        // Check if the emails match
        if (email.value.toLowerCase() !== confirmEmail.value.toLowerCase()) {
          confirmEmail.setErrors({ mismatch: true });
          return {
            mismatch: true
          };
        } else if (confirmEmail.errors && confirmEmail.errors.mismatch) {
          // If the errors object does not contain any other errors then set errors {} to null
          if (Object.keys(confirmEmail.errors).length == 1) {
            confirmEmail.setErrors(null);
          } else {
            // Delete the mismatch error from the object as we do not want to remove any other errors that exist in the {}
            delete confirmEmail.errors.mismatch;
          }
        } else {
          confirmEmail.setErrors(null);
          return null;
        }
      }
    };
  }

  // Initialise the form
  bookingQuestionsForm: FormGroup = this.formBuilder.group({});

  // Getter for easy access to form fields
  get form() {
    return this.customerDetailsForm.controls;
  }
  get bookingForm() {
    return this.bookingQuestionsForm.controls;
  }

  routerGoBack() {
    this.router.navigate(["/calendar"]);
  }

  restartJourney() {
    this.router.navigate(["/departments"]);
  }

  localAddress() {
    const e = this.customerDetailsForm.value.selectedAddress.address;
    const matchingCodes = this.custodianCodes.filter(code => {
      if (e.LOCAL_CUSTODIAN_CODE === code) {
        return true;
      }
    })
    if (matchingCodes.length > 0) return true;
    else return false;
  }

  checkAddress(addr: any) {
    const matchingCodes = this.custodianCodes.filter(code => {
      if (addr.LOCAL_CUSTODIAN_CODE === code) {
        return true;
      }
    })
    if (matchingCodes.length > 0) return true;
    else return false;
  }

  getAddress(repopulate: boolean = null) {
    this.showingAddresses = false;

    // Clear the addresses array
    this.addresses.length = 0;
    this.customerDetailsForm.controls.selectedAddress.setValue("");

    // Custom error handling for postcode
    let postcode: String = this.customerDetailsForm.value.postcode.replace(/\s/g, '');
    this.customerDetailsForm.controls.postcode.setValue(postcode)
    if (this.customerDetailsForm.controls.postcode.status == 'INVALID') {
      this.customerDetailsForm.get("postcode").setErrors({ 'invalidPostcode': this.translateService.instant('COMMON.INVALID_POSTCODE') })
      return;
    }
    this.spinner.show();
    this.apiService.get(this.customerDetailsForm.value.postcode).subscribe(async (res) => {
      if (this.customerDetailsForm.controls.postcode.errors && this.customerDetailsForm.controls.postcode.errors.invalidPostcode) {
        delete this.customerDetailsForm.controls.postcode.errors.invalidPostcode;
      }
      var object: any = res;
      if (object.results) {
        if (this.customerDetailsForm.controls.postcode.errors && this.customerDetailsForm.controls.postcode.errors.addressNotFound) {
          delete this.customerDetailsForm.controls.postcode.errors.addressNotFound;
        }
        var addresses = object.results;

        const addressesExist = addresses.filter(e => {
          if (e.DPA) {
            return true;
          } else if (e.LPI) {
            return true;
          }
        });

        const addressException = await this.apiService.getAddressException();
        let expections = [];

        if (addressException) {
          expections = addressException['Items'];
        }

        let added = [];

        if (addressesExist.length > 1) {
          // If it matches up, push the addresses
          let localCodes = [];
          addressesExist.forEach((item) => {

            const expectionAddress = expections.find(({ UPRN }) => {

              if (item.DPA) {
                if (UPRN === item.DPA.UPRN) {
                  return true;
                }
              }
              if (item.LPI) {
                if (UPRN === item.LPI.UPRN) {
                  return true;
                }
              }

              return false;

            });

            const addedAlready = added.find(({ ADDRESS }) => {

              if (item.DPA) {
                if (ADDRESS === item.DPA.ADDRESS) {
                  return true;
                }
              }
              if (item.LPI) {
                if (ADDRESS === item.LPI.ADDRESS) {
                  return true;
                }
              }

              return false;

            });

            let alreadyEntered = false;

            if (expectionAddress === undefined) {
              alreadyEntered = this.addresses.some(e => {
                if (item.DPA && item.DPA.UPRN === e.address.UPRN) {
                  return true;
                } else if (item.LPI && item.LPI.UPRN === e.address.UPRN) {
                  return true;
                }
              });
            }

            if (!alreadyEntered && addedAlready === undefined) {
              if (item.DPA) {
                const inLocalArea = this.checkAddress(item.DPA);
                if (inLocalArea) {
                  this.addresses.push({ address: item.DPA });
                  added.push({ ADDRESS: item.DPA.ADDRESS },);
                  localCodes.push(inLocalArea);
                }
              } else {
                const inLocalArea = this.checkAddress(item.LPI);
                if (inLocalArea) {
                  this.addresses.push({ address: item.LPI });
                  added.push({ ADDRESS: item.LPI.ADDRESS },);
                  localCodes.push(inLocalArea);
                }
              }
            }
          });

          if (repopulate) {
            let selectedAddress = this.customerDetailsForm.value.selectedAddress.address.ADDRESS
            for (let i = 0; i < this.addresses.length; i++) {
              let item = this.addresses[i]
              if (selectedAddress == item.address.ADDRESS) {
                this.selectedAddressId = i;
              }
            }
          }

          if (localCodes.length > 0) {
            this.showingAddresses = true;
            this.isInLocalArea = true;
          }
          else {
            this.showingAddresses = true;
            this.isInLocalArea = false;
          }
        } else {
          // If it doesn't match up, show an error message
          this.customerDetailsForm.get("postcode").setErrors({ 'invalidPostcode': this.translateService.instant('COMMON.ADDRESS_NOT_ELIGIBLE') })
        }
      } else {
        this.customerDetailsForm.get("postcode").setErrors({ 'addressNotFound': this.translateService.instant('COMMON.ADDRESS_NOT_FOUND') })
      }
      this.spinner.hide();
    }, err => {
      this.customerDetailsForm.get("postcode").setErrors({ 'invalidPostcode': this.translateService.instant('COMMON.INVALID_POSTCODE') })
      this.spinner.hide();
    });
  }


  bannedRegistrationCheck(registration) {
    // Banned vehicle registrations from API

    if (this.parentDepartment.extra.banned_vehicle_registration_numbers) {
      // If there are any banned vehicle registration numbers, run the check
      var str = this.parentDepartment.extra.banned_vehicle_registration_numbers.replace(/\s/g, '').toLowerCase();
      var banned = new Array();
      banned = str.split(",");

      // Value to return
      var canContinue: Boolean = true;
      // Check for match between banned registrations and users registration
      banned.forEach(item => {
        if (item == registration.replace(/\s/g, '').toLowerCase()) {
          canContinue = false;
        }
      });
      return canContinue;
    } else {
      // If there are no banned vehicle registration numbers, always return true
      return true;
    }

  }


  formatPostcode() {

  }

  ngOnInit() {
    this.scrollToTop();

    if (this.addressEnabled) {
      this.customerDetailsForm.addControl('postcode', new FormControl("", [Validators.required, Validators.pattern(this.postcodeValidator)]));
      this.customerDetailsForm.addControl('selectedAddress', new FormControl("", Validators.required));
    }

    this.availabilityService.getSelectedSlot().then(slot => {
      if (slot) {
        this.slot = slot
      }
    })

    this.parentDepartment = this.datastoreService.parentDepartment;

    if (this.parentDepartment) {
      this.spinner.show();
      this.selectedDepartment = this.datastoreService.selectedDepartment;
      let registrationOption = '';
      let organisationQuestionsID;

      // Set the title on the login page to the title from the business question
      this.parentDepartment.extra?.hasOwnProperty(
        "booking_details_page_title"
      )
        ? (this.pageTitle = this.parentDepartment.extra.booking_details_page_title)
        : (this.pageTitle = "Booking details title not set");

      // Get the configurable data
      if (this.parentDepartment.hasOwnProperty("extra")) {
        // Set the extra text on the page to the text taken from the business question

        this.parentDepartment.extra.hasOwnProperty("data_policy")
          ? (this.dataPolicy = this.parentDepartment.extra.data_policy)
          : (this.dataPolicy = "data policy not set");
        this.parentDepartment.extra.hasOwnProperty("data_policy_top")
          ? (this.dataPolicyTop = this.parentDepartment.extra.data_policy_top)
          : (this.dataPolicyTop = null);

        this.parentDepartment.extra.hasOwnProperty("declaration_question_override")
          ? (this.declaration_question_override = this.parentDepartment.extra.declaration_question_override)
          : (this.declaration_question_override = "declaration question not set");

        this.parentDepartment.extra.hasOwnProperty(
          "booking_details_page_text"
        )
          ? (this.bookingDetailsPageText = this.parentDepartment.extra.booking_details_page_text)
          : null;

        // Set the extra text on the page to the text taken from the business question
        this.parentDepartment.extra.hasOwnProperty(
          "booking_details_page_text"
        )
          ? (this.bookingDetailsPageText = this.parentDepartment.extra.booking_details_page_text)
          : null;
        // Get the registration option
        this.parentDepartment.extra.hasOwnProperty("registration_option")
          ? (registrationOption = this.parentDepartment.extra
            .registration_option)
          : null;
        // Get the organistion customer questions
        this.parentDepartment.extra.hasOwnProperty(
          "organisations_questions"
        )
          ? (organisationQuestionsID = this.parentDepartment.extra
            .organisations_questions)
          : null;

        // If the register option is set to complete a full registration then capture the following details
        if (registrationOption.toLowerCase() === "register complete details") {
          this.requiresFullRegistration = true;
        }
      }

      //     // Use this.departmentService.getLocation() if the services are set on the children departments
      //     // Use this.departmentService.get() if the services are set on the parent department
      this.departmentService.getLocation().then(async (location) => {
        this.selectedLocation = location;

        // Delete any exisitng baskets to avoid conflicts
        if (this.basketservice.currentBasket) {
          await this.basketservice.delete(
            this.selectedLocation,
            this.basketservice.currentBasket
          );
        }
        // Create the basket
        this.basketservice.createBasket(location).then(
          (basket) => {
            this.basketservice.currentBasket = basket;

            this.memberService.getMember().then((member) => {
              this.member = member;

              // Get the customer questions
              this.departmentService.getCustomerQuestions().then((questions) => {

                this.customerQuestions = questions;
                this.datastoreService.customerQuestions = questions;
                for (let index = 0; index < this.customerQuestions.length; index++) {
                  const question = this.customerQuestions[index];
                  question.visible = false;
                  question.reference = question.id;
                  if (!question.admin_only) {
                    if (!question.settings.hasOwnProperty('conditional_question')) {
                      question.visible = true;
                    }

                    const questionControl = question.id;
                    if (question.detail_type == 'check' || question.detail_type == 'check-price') {
                      if (question.default == 1) {
                        if (question.required) {
                          this.customerDetailsForm.addControl(questionControl, new FormControl(true, Validators.required));
                        } else {
                          this.customerDetailsForm.addControl(questionControl, new FormControl(true));
                        }
                      } else {
                        if (question.required) {
                          this.customerDetailsForm.addControl(questionControl, new FormControl(false, Validators.required));
                        } else {
                          this.customerDetailsForm.addControl(questionControl, new FormControl(false));
                        }
                      }
                    } else {
                      if (questionControl === 382) {  // postcode custom question needs validation
                        // if (!this.addressEnabled) {  // postcode custom question not needed if we are doing address lookup
                        //   if (question.required) {
                        //     this.customerDetailsForm.addControl(questionControl, new FormControl('', [Validators.required, Validators.pattern(this.postcodeValidator)]));
                        //   } else {
                        //     this.customerDetailsForm.addControl(questionControl, new FormControl('', Validators.pattern(this.postcodeValidator)));
                        //   }
                        //   this.customerDetailsForm.get("382").valueChanges.subscribe(value => { //force uppercase and space removal
                        //     if (value !== value.replace(/\s/g, "").toUpperCase().slice(0, 8)) {
                        //       this.customerDetailsForm.controls["382"].setValue(value.replace(/\s/g, "").toUpperCase().slice(0, 8));
                        //     }
                        //   });
                        // }
                      } else {
                        if (question.required) {
                          this.customerDetailsForm.addControl(questionControl, new FormControl('', Validators.required));
                        } else {
                          this.customerDetailsForm.addControl(questionControl, new FormControl(''));
                        }
                      }
                    }
                  }
                }

              });
            });
            this.spinner.hide();
          },
          (err) => {
            this.alertService.error(
              this.translateService.instant("COMMON.GENERAL_ERR")
            );
            this.spinner.hide();
          }
        );
      });

      //     // Get the selected service
      this.servicesService.get().then((service) => {
        this.selectedService = service;

        // Get the questions
        this.selectedService.$get("questions").then((res) => {
          let bookingQuestions = [];
          for (const q of res.questions) {
            if (this.selectedLocation.id === 37002 || this.selectedLocation.id === 37003 || this.selectedLocation.id === 37004 || this.selectedLocation.id === 37005 || this.selectedLocation.id === 37006) {
              if (q.id !== 10) {
                bookingQuestions.push(q);
              }
            } else {
              bookingQuestions.push(q);
            }
          }
          this.bookingQuestions = bookingQuestions;
          this.datastoreService.bookingQuestions = this.bookingQuestions;

          for (let index = 0; index < bookingQuestions.length; index++) {
            const question = bookingQuestions[index];
            question.visible = false;
            question.reference = question.id;

            if (!question.admin_only) {
              // Check if the question has conditional parent
              if (question.settings.hasOwnProperty("conditional_question")) {
                const conditionalParent = this.customerQuestions.find(x => question.settings.conditional_question.toString() === x.id.toString());
                if (conditionalParent) {
                  const conditionalParentValue = this.bookingQuestionsForm.get(conditionalParent.id.toString()).value;
                  // Check if conditional parent value matches the expected answer
                  if (conditionalParentValue === question.settings.conditional_answer) {
                    question.visible = true;
                  }
                }
              } else {
                question.visible = true;
              }

              const questionControl = question.id;

              if (question.detail_type == 'check' || question.detail_type == 'check-price') {
                if (question.required) {
                  this.bookingQuestionsForm.addControl(questionControl, new FormControl(false, Validators.requiredTrue));
                } else {
                  this.bookingQuestionsForm.addControl(questionControl, new FormControl(false));
                }
              } else if (question.detail_type === 'text_field') {
                if (question.required) {
                  this.bookingQuestionsForm.addControl(questionControl, new FormControl('', Validators.required));
                } else {
                  this.bookingQuestionsForm.addControl(questionControl, new FormControl(''));
                }
              } else {
                if (question.required) {
                  this.bookingQuestionsForm.addControl(questionControl, new FormControl('', Validators.required));
                } else {
                  this.bookingQuestionsForm.addControl(questionControl, new FormControl(''));
                }
              }
            }
          }
          // Repopulate the booking questions when coming back
          this.datastoreService.bookingForm
            ? this.repopulateQuestions()
            : null;
        });

      });
    } else {
      this.restartJourney();
    }
  }

  repopulateQuestions() {
    this.bookingQuestionsForm = this.datastoreService.bookingForm;
    this.customerDetailsForm = this.datastoreService.customerDetailsForm;
    this.onQuestionChange();
    this.onCustomerQuestionChange();
  }

  onCustomerQuestionChange() {
    const bookingQuestions = this.customerQuestions;
    this.underAgeIssue = false;
    // Conditional questions logic
    for (let index = 0; index < bookingQuestions.length; index++) {
      const q = bookingQuestions[index];

      // If the question has settings and relies on a conditional question
      if (q.settings && q.settings.hasOwnProperty("conditional_question")) {
        // Get the conditional question based on the id set in q
        const conditionalQ = bookingQuestions.filter(
          (question) => question.id == q.settings.conditional_question
        );
        // Get the question control
        const questionControl = this.form[q.settings.conditional_question];
        // If the question control has a value get the option from the contional question. This will allow
        // us to check if q is shown when value is selected
        if (conditionalQ[0].hasOwnProperty('options')) {
          const option = conditionalQ[0].options.filter(
            (option) => option.name == questionControl.value
          );
          // If the conditional question is visible and the value chosen === to qs conditional answer then show q
          if (
            conditionalQ.length > 0 &&
            conditionalQ[0].visible &&
            option.length > 0 &&
            q.settings.conditional_answers.hasOwnProperty(option[0].id)
          ) {
            this.form[q.id].enable();
            // If the condiditonal question is important, Hide it
            if (q.important) {
              q.visible = false;
            } else {
              q.visible = true;
            }
          } else {
            q.visible = false;
            this.form[q.id].setValue("");
            this.form[q.id].disable();
          }
        }
        else {
          if
            (
            questionControl.value &&
            conditionalQ.length > 0 &&
            conditionalQ[0].visible
          ) {
            this.bookingForm[q.id].enable();
            if (q.important) {
              q.visible = false;
            } else {
              q.visible = true;
            }
          } else {
            q.visible = false;
            this.bookingForm[q.id].setValue("");
            this.bookingForm[q.id].disable();
          }
        }
      }

      // are_you_over_the_age_of_18 question
      if (q.id.toString() === "16") {
        if (this.form[q.id]) {
          if (this.form[q.id].value && this.form[q.id].value === "No") {
            this.customerDetailsForm.get(`${q.reference}`).setErrors({ underage: true });
            this.underAgeIssue = true;
            // this.subtmitButtonDisabled = true;
          } else {
            // this.form[q.id].setErrors(null);
          }
        }

      }
      // is_the_patient_over_the_age_of_18 question
      if (q.id.toString() === "17") {
        if (this.form[q.id]) {
          if (this.form[q.id].value && this.form[q.id].value === "No") {
            this.customerDetailsForm.get(`${q.reference}`).setErrors({ underage_patient: true });
            this.underAgeIssue = true;
            // this.subtmitButtonDisabled = true;
          } else {
            // this.form[q.reference].setErrors(null);
          }
        }
      }
      // do_you_have_the_patients_consent_to_provide_their
      if (q.id.toString() === "18") {
        if (this.form[q.id]) {
          if (this.form[q.id].value && this.form[q.id].value === "No") {
            this.customerDetailsForm.get(`${q.reference}`).setErrors({ patient_consent: true });
            this.underAgeIssue = true;
            // this.subtmitButtonDisabled = true;
          } else {
            // this.form[q.id].setErrors(null);
          }
        }
      }
    }
  }

  async onQuestionChange() {
    const bookingQuestions = this.bookingQuestions;
    // Conditional questions logic
    for (let index = 0; index < bookingQuestions.length; index++) {
      const q = bookingQuestions[index];
      // here we are checking that there are 2 slots available if the user is trying to bring a carer or supporter along
      if (q.id.toString() === "5") {
        if (this.bookingForm[q.id].value === "Yes") {
          if (this.last5Value !== this.bookingForm[q.id].value) { // only do it if we haven't already checked
            let location = await this.departmentService.getLocation();
            // get service for selected location
            let selectedLocationService = await this.servicesService.getServices(location);
            // select the first service from the selected location
            let selectedService = selectedLocationService[0];
            const selectedSlot = await this.availabilityService.getSelectedSlot()
            const params = {
              'service_id': selectedService.id,
              'date': selectedSlot.datetime,
              'end_date': selectedSlot.datetime,
              'resource_id': selectedSlot.resource_id
            };
            // Only add slots with availability
            const timeslots: any = await this.availabilityService.getAvailableSlots(location, params);
            let avail = 0
            if (timeslots?._embedded?.events) { // count the number of available slots
              const timeInMin = moment.duration(moment(selectedSlot.datetime).format("HH:mm")).asMinutes()
              for (let e of timeslots?._embedded?.events) {
                for (let t of e.times) {
                  if (t.time.toString() === timeInMin.toString()) {
                    avail += t.avail
                  }
                }
              }
              this.countFor5 = avail
            }
          }
        } else {
          this.countFor5 = -1;
        }
        this.last5Value = this.bookingForm[q.id].value
      }

      // If the question has settings and relies on a conditional question
      if (q.settings && q.settings.hasOwnProperty("conditional_question")) {
        // Get the conditional question based on the id set in q
        const conditionalQ = bookingQuestions.filter(
          (question) => question.id == q.settings.conditional_question
        );
        // Get the question control
        const questionControl = this.bookingForm[q.settings.conditional_question];
        // If the question control has a value get the option from the contional question. This will allow
        // us to check if q is shown when value is selected
        if (conditionalQ[0].hasOwnProperty('options')) {
          const option = conditionalQ[0].options.filter(
            (option) => option.name == questionControl.value
          );
          // If the conditional question is visible and the value chosen === to qs conditional answer then show q
          if (
            conditionalQ.length > 0 &&
            conditionalQ[0].visible &&
            option.length > 0 &&
            q.settings.conditional_answers.hasOwnProperty(option[0].id)
          ) {
            this.bookingForm[q.id].enable();
            // If the condiditonal question is important, Hide it
            if (q.important) {
              q.visible = false;
            } else {
              q.visible = true;
            }
          } else {
            q.visible = false;
            this.bookingForm[q.id].setValue("");
            this.bookingForm[q.id].disable();
          }
        }
        else {
          if
            (
            questionControl.value &&
            conditionalQ.length > 0 &&
            conditionalQ[0].visible
          ) {
            this.bookingForm[q.id].enable();
            if (q.important) {
              q.visible = false;
            } else {
              q.visible = true;
            }
          } else {
            q.visible = false;
            this.bookingForm[q.id].setValue("");
            this.bookingForm[q.id].disable();
          }
        }
      }
    }
  }

  onFileChange(event, questionId) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form[questionId].setValue(file, { emitModelToViewChange: false });
      this.bookingQuestionsForm.patchValue({
        fileSource: file,
      });
    }
  }

  mobileChange() {
    this.customerDetailsForm.controls['mobile_number'].setValue(this.customerDetailsForm.controls['mobile_number'].value.replace(/[^0-9]/g, ''))
    if (this.customerDetailsForm.controls['mobile_number'].value.length > 11) {
      this.customerDetailsForm.controls['mobile_number'].setValue(this.customerDetailsForm.controls['mobile_number'].value.slice(0, 11))
    }

  }

  async onSubmit() {
    this.spinner.show();
    this.submitted = true;
    this.alertService.clear();

    const emailField = this.customerDetailsForm.controls['email_address'];
    const confirmField = this.customerDetailsForm.controls['confirm_email'];
    let phoneField = this.customerDetailsForm.controls['mobile_number'];

    if (emailField.value.length > 0) {
      phoneField.clearValidators();
      phoneField.updateValueAndValidity();
      emailField.setValidators(this.emailValidators);
      emailField.updateValueAndValidity();
    }

    if (phoneField.value.length > 0) {
      emailField.clearValidators();
      emailField.updateValueAndValidity();
      phoneField.setValidators(this.phoneValidators);
      phoneField.updateValueAndValidity();
    }

    if ((phoneField.value.length < 1 && emailField.value.length < 1) ||
      phoneField.value.length > 0 && emailField.value.length > 0) {
      phoneField.setValidators(this.phoneValidators);
      phoneField.updateValueAndValidity();
      emailField.setValidators(this.emailValidators);
      emailField.updateValueAndValidity();

    }

    // Check if form is valid
    if (this.customerDetailsForm.invalid || this.bookingQuestionsForm.invalid || this.countFor5 === 1 || this.countFor5 === 0) {
      this.scrollToTop();
      this.spinner.hide();
      this.alertService.warn(this.translateService.instant('FORM.VALIDATION_ERR_MSG'));
      return;
    }

    var currentDate = new Date(this.slot.datetime);

    // for some reason this function sometimes doesn't get run, adding the console logs fixed this (not sure why)

    this.completeBooking()

    for (let index = 0; index < this.customerQuestions.length; index++) {
      const q = this.customerQuestions[index];

      if (q.id == 5) {
        const question5 = this.customerQuestions.filter(
          (question) => question.id == 5
        );
        const questionID5 = this.form[5];

        if (question5[0].hasOwnProperty('options')) {
          const option = question5[0].options.filter(
            (option) => option.name == questionID5.value
          );
          if (
            question5.length > 0 &&
            question5[0].visible &&
            option.length > 0 &&
            questionID5.hasOwnProperty(option[0].id)
          ) {
            return true;
          }
        }
      }
    }
    return false;
  }



  async completeBooking() {

    // now check restricetd waste
    for (const q of this.bookingQuestions) {
      const value = this.bookingQuestionsForm.value[q.id];
      if (q.id === 8) {
        this.datastoreService.isRestrictedBooking = value;
      }
      if (q.id === 11) {
        this.datastoreService.isRestrictedAsbestosBooking = value;
      }

      // Commercial wast check
      if (q.id === 10) {
        this.datastoreService.isCommercialBooking = value;
      }
    }

    if (this.addressEnabled) {

      if (!this.customerDetailsForm.value.selectedAddress.address) {
        this.alertService.warn(this.translateService.instant('FORM.VALIDATION_ERR_MSG'));
        this.scrollToTop();
        this.spinner.hide();
        return;
      }

      let addressFirstPart;
      let selectedAddress;
      if (this.customerDetailsForm.value.selectedAddress.address) {
        selectedAddress = this.customerDetailsForm.value.selectedAddress.address;
        if (selectedAddress.LPI_KEY) {
          // IF LPI
          if (selectedAddress.PAO_START_NUMBER) {
            addressFirstPart = `${selectedAddress.SAO_TEXT ? selectedAddress.SAO_TEXT : ''} ${selectedAddress.PAO_START_NUMBER}`;
          } else if (selectedAddress.PAO_TEXT) {
            addressFirstPart = `${selectedAddress.SAO_TEXT ? selectedAddress.SAO_TEXT : ''} ${selectedAddress.PAO_TEXT}`;
          }
        } else {
          // IF DPA
          if (this.customerDetailsForm.value.selectedAddress.address.BUILDING_NUMBER && this.customerDetailsForm.value.selectedAddress.address.ORGANISATION_NAME) {
            addressFirstPart = `${this.customerDetailsForm.value.selectedAddress.address.ORGANISATION_NAME}, ${this.customerDetailsForm.value.selectedAddress.address.BUILDING_NAME}, ${this.customerDetailsForm.value.selectedAddress.address.BUILDING_NUMBER},`;
          } else {
            if (this.customerDetailsForm.value.selectedAddress.address.BUILDING_NUMBER && this.customerDetailsForm.value.selectedAddress.address.ORGANISATION_NAME) {
              addressFirstPart = `${this.customerDetailsForm.value.selectedAddress.address.BUILDING_NUMBER}, ${this.customerDetailsForm.value.selectedAddress.address.ORGANISATION_NAME}`;
            } else if (this.customerDetailsForm.value.selectedAddress.address.BUILDING_NAME && this.customerDetailsForm.value.selectedAddress.address.ORGANISATION_NAME) {
              addressFirstPart = `${this.customerDetailsForm.value.selectedAddress.address.ORGANISATION_NAME}, ${this.customerDetailsForm.value.selectedAddress.address.BUILDING_NAME}`;
            } else {
              if (this.customerDetailsForm.value.selectedAddress.address.BUILDING_NUMBER) {
                addressFirstPart = this.customerDetailsForm.value.selectedAddress.address.BUILDING_NUMBER;
              } else if (this.customerDetailsForm.value.selectedAddress.address.BUILDING_NAME) {
                addressFirstPart = this.customerDetailsForm.value.selectedAddress.address.BUILDING_NAME;
              } else {
                addressFirstPart = this.customerDetailsForm.value.selectedAddress.address.ORGANISATION_NAME;
              }
            }
          }
        }
      } else {
        this.alertService.warn(this.translateService.instant('FORM.VALIDATION_ERR_MSG'));
        this.scrollToTop();
        this.spinner.hide();
        this.customerDetailsForm.get("selectedAddress").setErrors({
          'required': this.translateService.instant('COMMON.ADDRESS_NOT_FOUND')
        })
        return;
      }

      // Fix issue where the thoroughfare name and street description are not available.
      let address1 = '';
      if (this.customerDetailsForm.value.selectedAddress.address.THOROUGHFARE_NAME || this.customerDetailsForm.value.selectedAddress.address.STREET_DESCRIPTION) {
        address1 = `${addressFirstPart.toLowerCase()} ${this.customerDetailsForm.value.selectedAddress.address.THOROUGHFARE_NAME ? this.customerDetailsForm.value.selectedAddress.address.THOROUGHFARE_NAME.toLowerCase() :
          this.customerDetailsForm.value.selectedAddress.address.STREET_DESCRIPTION.toLowerCase()}`;
      }
      else {
        address1 = `${addressFirstPart.toLowerCase()} ${this.customerDetailsForm.value.selectedAddress.address.DEPENDENT_LOCALITY}`;
      }

      this.datastoreService.address1 = selectedAddress.ADDRESS.toLowerCase();

      // Check our API if the user has booked already in the past 7 days
      // If there is a weekly booking limit

      let limitReached = false;

      let limitMonth = this.parentDepartment.extra.booking_limit_per_household_per_week_mon_to_sun;
      let limitYear = 0;
      let limitAsbestos = 0;

      // await this.apiService.getWeekOfBookings(address1, this.selectedDepartment, this.slot.datetime, weeklyLimit).then(res => {

      //   // if limitReached is true then user has reached the weekly limit (which is set by the client)
      //   if (res['limitReached'] == true) {
      //     limitReached = true;
      //     this.alertService.error(this.translateService.instant('FORM.HOUSEHOLD_WEEKLY_BOOKING_LIMIT', { limit: weeklyLimit }));
      //     this.scrollToTop();
      //     this.spinner.hide();
      //     return;
      //   } else if (res['Error']) {
      //     limitReached = true;
      //     this.alertService.warn(this.translateService.instant('COMMON.VALIDATION_ERR_MSG'));
      //     this.scrollToTop();
      //     this.spinner.hide();
      //     return;
      //   }
      // });
      let failRestricted = false;
      let failRestrictedAsbestos = false;
      try {
        if (!this.datastoreService.isCommercialBooking) {
          const slot: any = await this.availabilityService.getSelectedSlot();
          const slotDateTime = slot.datetime;
          const selectedService = await this.servicesService.get();
          const bookingLimitData: any = await this.apiService.checkBookingLimit(this.datastoreService.parentDepartment, this.datastoreService.address1, slotDateTime, selectedService);
          limitReached = !bookingLimitData.status;
          limitMonth = bookingLimitData.limits.monthlyLimit;
          limitYear = bookingLimitData.limits.yearlyLimit;
          limitAsbestos = bookingLimitData.limits.restrictedAsbestosLimit;

          if (!limitReached && this.datastoreService.isRestrictedBooking) {
            const restrictedBookingLimitData: any = await this.apiService.checkBookingLimit(this.datastoreService.parentDepartment, `${this.datastoreService.address1}_RESTRICTED`, slotDateTime, selectedService);
            limitReached = !restrictedBookingLimitData.status;
            if (limitReached) {
              failRestricted = true;
            }

          }

          // now check the asbestos
          if (!limitReached && this.datastoreService.isRestrictedAsbestosBooking) {
            // make site string
            const slotTime = moment(slot.datetime);
            const siteString = `ASBESTOS_${slotTime.format('YYYY-MM-DD')}`;
            this.datastoreService.restrictedAsbestosKey = siteString;
            const restrictedBookingLimitData: any = await this.apiService.checkBookingLimit(this.datastoreService.selectedDepartment, siteString, slotDateTime, selectedService);
            limitReached = !restrictedBookingLimitData.status;
            if (limitReached) {
              failRestrictedAsbestos = true;
            }

          }
        }
      }
      catch (err) {
        console.error("failed to check address limit");
        console.error(err);
      }

      if (limitReached) {
        if (failRestricted) {
          this.alertService.error(this.translateService.instant('FORM.HOUSEHOLD_RESTRICTED_BOOKING_LIMIT', { limitMonth: limitMonth, limitYear: limitYear }));
        }
        else {
          if (failRestrictedAsbestos) {
            this.alertService.error(this.translateService.instant('FORM.HOUSEHOLD_RESTRICTED_ASBESTOS_BOOKING_LIMIT', { limitDay: limitAsbestos }));
          }
          else {
            this.alertService.error(this.translateService.instant('FORM.HOUSEHOLD_WEEKLY_BOOKING_LIMIT', { limitMonth: limitMonth, limitYear: limitYear }));
          }
        }

        this.scrollToTop();
        this.spinner.hide();
        return;
      }
    }

    await this.completeDetails()

  }


  async completeDetails() {

    const email = this.customerDetailsForm.controls['email_address'].value;

    // Use email/phone to check if the user is banned
    // let res = await this.apiService.checkEmailNumber(email, null, this.selectedDepartment);

    // let checkAccount = new Promise((resolve, reject) => {
    //   if (res == undefined) {
    //     resolve("finished")
    //   }
    //   if (res["_source"] && res["_source"].questions) {
    //     if (res["_source"].questions.length > 0) {
    //       res["_source"].questions.forEach((question, index) => {
    //         if (question.id == 8 && question.answer == "1") {
    //           reject("banned")
    //           this.banned = true;
    //           return;
    //         }
    //         if (index === res["_source"].questions.length - 1) {
    //           resolve("finished");
    //         }
    //       });
    //     } else {
    //       resolve("finished");
    //     }
    //   } else {
    //     resolve("finished");
    //   }
    // });

    // checkAccount.then(() => {
    this.datastoreService.bookingForm = this.bookingQuestionsForm;
    this.datastoreService.customerDetailsForm = this.customerDetailsForm;
    this.spinner.hide();

    // var currentSlot = new Date(this.slot.datetime).toTimeString();

    // let canContinue = true;
    // this.unavailableTimes.forEach(bookedSlot => {
    //   if (currentSlot.includes(bookedSlot)) {
    //     // if the current slot matches an unavailable time, +1 to can continue (doing it like this so it doesn't break the loop)
    //     canContinue = (false)
    //   }
    // });
    // If canContinue = (there is a match between current slot and unavailable time), then show error, otherwise continue
    // if (!canContinue) {
    //   this.alertService.error(
    //     this.translateService.instant('COMMON.BACK_TO_BACK_BOOKINGS')
    //   );
    //   this.scrollToTop();
    //   this.spinner.hide();
    //   return;
    // } else {
    // if (this.bookingQuestionsForm.value[4]) {
    //   if (this.bannedRegistrationCheck(this.datastoreService.bookingForm.value[4])) {
    //     this.router.navigate(["/summary"]);
    //   } else {
    //     this.scrollToTop();
    //     this.alertService.warn(this.translateService.instant('COMMON.USER_BANNED'));
    //   }
    // } else {
    this.router.navigate(["/summary"]);
    // }
    // }
    // }).catch((e) => {
    //   if (e == "banned") {
    //     this.alertService.warn(this.translateService.instant('COMMON.USER_BANNED'));
    //   } else {
    //     this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'));
    //   }
    //   this.scrollToTop();
    //   this.spinner.hide();
    // });
  }

  // Scroll to the top of the page
  scrollToTop() {
    window.scroll(0, 0);
  }

  // Number input text validation, keycode 8 is backspace
  keyPressNumberField(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
