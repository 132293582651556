import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from 'src/app/app.config';
import { context } from 'bookingbug-core-js';
import { environment } from 'src/environments/environment';
import { DatastoreService } from 'src/app/services/datastore.service';

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private core = AppConfig.settings.addressApi;
  private apiServer = 'https://staging.bookinglab.co.uk'; //(window.location.hostname == 'localhost') ? 'http://localhost:4201' :
  private token = '177d111d2b35be402209ef0f8ff437d5a365e16bd79e9182b8e439fe10dd6933';
  public osCount = 0;
  constructor(
    private http: HttpClient,
    private datastoreService: DatastoreService,
  ) { }

  get(postcode) {
    const key = this.core.key
    return this.http.get(
      `https://api.os.uk/search/places/v1/postcode?postcode=${postcode}&key=${key}&dataset=DPA,LPI`
    );
  }

  async getNearestCompanies(postcode, company) {
    const headers = {
      'Content-Type': 'application/json',
      'clienttoken': environment.clientToken
    };

    const formattedPostcode = encodeURIComponent(postcode);

    return await this.http.get(`${environment.apiServer}/company/${company}/nearest/${formattedPostcode}`, { headers }).toPromise();
  }

  async getWeekOfBookings(address, company, slot_datetime, weeklyLimit) {
    const headers = { 'Content-Type': 'application/json', "X-BL-TOKEN": `${this.token}` };
    const body = {
      "host": `${context.apiUrl}`,
      "company_id": `${company.id}`,
      "limit": `${company.extra.booking_limit_days}`,
      "address": `${address.substring(0, 50)}`, // Address in jrni is capped to 50 characters
      "current_date": slot_datetime,
      "weeklyLimit": weeklyLimit
    }

    return await this.http.post(`${this.apiServer}/bookingLimit`, body, { headers }).toPromise();
  }

  async checkEmailNumber(email, mobile, company) {
    const parent = await company.$get('parent');
    const headers = { 'Content-Type': 'application/json', "X-BL-TOKEN": `${this.token}` };
    const body = {
      "host": `${context.apiUrl}`,
      "company_id": `${company.id}`,
      "parent_id": `${parent.id}`,
      "email": email ? email : mobile,
      "searchType": "elastic"
    }

    return await this.http.post(`${this.apiServer}/findElasticClient`, body, { headers }).toPromise();
  }

  async getBookingsForUser(company, email) {
    const parent = await company.$get('parent');
    const headers = { 'Content-Type': 'application/json', "X-BL-TOKEN": `${this.token}` };
    const body = {
      "host": `${context.apiUrl}`,
      "parent_id": `${parent.id}`,
      "company_id": `${company.id}`,
      "email": `${email}`,
    }

    return await this.http.post(`${this.apiServer}/findElasticClient`, body, { headers }).toPromise();
  }

  async checkForDoubleBooking(company, slot, email = null, mobile = null) {
    const parent = await company.$get('parent');
    const headers = { 'Content-Type': 'application/json', "X-BL-TOKEN": `${this.token}` };
    const body = {
      "host": `${context.apiUrl}`,
      "company_id": `${company.id}`,
      "parent_id": `${parent.id}`,
      "current_date": slot,
    }

    if (email) {
      body["email"] = email;
    } else {
      body["mobile"] = mobile;
    }

    // return this.http.post('https://staging.bookinglab.co.uk/unavailableTimes', body, { headers }).toPromise();
    return this.http.post(`${this.apiServer}/unavailableTimes`, body, { headers }).toPromise();
  }

  async checkSlotAvailability(company, slot) {
    const headers = { 'Content-Type': 'application/json', "X-BL-TOKEN": `${this.token}` };
    const body = {
      "host": `${context.apiUrl}`,
      "company_id": `${company.id}`,
      "slot": slot,
    }

    return this.http.post(`${this.apiServer}/slotAvailability`, body, { headers }).toPromise();
  }

  async getAddressException() {
    const headers = { 'Content-Type': 'application/json', 'X-BL-TOKEN': `${this.token}` };
    const body = {
      "host": `${context.apiUrl}`
    }

    return await this.http.post(`${this.apiServer}/addressException`, body, { headers }).toPromise();
  }

  async checkBookingLimit(company, matchString, slot, service) {
    const headers = { 'Content-Type': 'application/json', 'X-BL-TOKEN': `${this.token}` };
    const body = {
      "host": `${context.apiUrl}`,
      "company_id": `${company.id}`,
      "match_string": `${matchString.trim()}`,
      "slot": `${slot}`,
      "max_time": service.max_advance_period
    }

    return await this.http.post(`${this.apiServer}/checkBookingLimit`, body, { headers }).toPromise();
  }

  async updateBookingLimit(company, matchString, slot) {
    const headers = { 'Content-Type': 'application/json', 'X-BL-TOKEN': `${this.token}` };
    const body = {
      "host": `${context.apiUrl}`,
      "company_id": `${company.id}`,
      "match_string": `${matchString.trim()}`,
      "slot": `${slot}`
    }

    return await this.http.post(`${this.apiServer}/updateBookingLimit`, body, { headers }).toPromise();
  }


  async getConfig(site: string, comp: string) {
    // console.log(`Loading '${environment.companyName}' config.`);
    const headers = {
      'Content-Type': 'application/json',
      'clienttoken': environment.clientToken
    };
    const body = {
      "client": site,
      "company": comp
    }

    return await this.http.post(`${environment.apiServer}/config`, body, { headers }).toPromise();
  }

  async getToken(site: string, comp: string) {
    // console.log(`Loading '${environment.companyName}' config.`);
    const headers = {
      'Content-Type': 'application/json',
      'clienttoken': environment.clientToken
    };
    const body = {
      "client": site,
      "company": comp
    }

    return await this.http.post(`${environment.apiServer}/token`, body, { headers }).toPromise();
  }

  async getWasteLimit(parent_id: number, matchString: string, date: string) {
    try {

      const headers = {
        'Content-Type': 'application/json',
        'clienttoken': environment.clientToken
      };
      return await this.http.get(`${environment.apiServer}/waste?company=${parent_id}&matchString=${matchString}&date=${date}`, { headers }).toPromise();
    } catch (error) {
      return (error as Error).message;
    }
  }

  async getShortenedUrl(target) {
    try {

      const headers = {
        'Content-Type': 'application/json',
      };
      return await this.http.get(`${environment.urlShortener}?target=${target.trim()}`, { headers }).toPromise();
    } catch (error) {
      return (error as Error).message;
    }
  }

  async osGetNameData(name: any, searchCounter: number) {
    // get the key
    let res: any = []
    console.log(this.datastoreService.config)
    if (this.datastoreService.config?.me) {
      const client = this.datastoreService.config.me.split(":")[0]
      res = await this.osGetNameDataEncodedApi(name, client)
    } else {
      res = []// await this.apiService.osGetNameData(name);
      console.log("warning, OS key not set in config!")
      console.log("add it to keys in the format OS:'{key}'")
    }
    let nameData = res.results;
    let n = nameData.length;
    if (n > 5) { // trim down the massive list a bit
      n = 5
    }
    nameData = nameData.slice(0, n)
    return { nameData: nameData, n: searchCounter }
  }

  async osGetNameDataApi(name: string, key: string) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'no-loader': 'no-loader',
        'clienttoken': environment.clientToken
      };
      return await this.http.get(`${environment.apiServer}/ordnance_survey/${key}/name/${name}`, { headers }).toPromise();
    } catch (error) {
      return (error as Error).message;
    }
  }

  async osGetNameDataEncodedApi(name: string, client: string) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'no-loader': 'no-loader',
        'clienttoken': environment.clientToken
      };
      return await this.http.get(`${environment.apiServer}/ordnance_survey/${client}/name_encoded/${name}`, { headers }).toPromise();
    } catch (error) {
      return (error as Error).message;
    }
  }

  async coordConvertEWtoLL(xCoord: number, yCoord: number) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'clienttoken': environment.clientToken
      };
      return await this.http.get(`${environment.apiServer}/coordConvertEWtoLL/${xCoord}/${yCoord}`, { headers }).toPromise();
    } catch (error) {
      return (error as Error).message;
    }
  }
}