import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { MemberService } from "src/app/services/jrni/member.service";
import { DepartmentService } from "src/app/services/jrni/department.service";
import { Router } from "@angular/router";
import { AlertService } from "src/app/_alert";
import { ServicesService } from "src/app/services/jrni/services.service";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { DatastoreService } from "src/app/services/datastore.service";
import { AvailabilityService } from "src/app/services/jrni/availability.service";
import { AppConfig } from "src/app/app.config";

@Component({
  selector: "app-services",
  templateUrl: "./services.component.html",
  styleUrls: ["./services.component.scss"],
})
export class ServicesComponent implements OnInit {
  selectedDepartment;
  pageTitle;
  servicePageText;
  servicesList: any = [];
  selectedService;
  private customerQuestions = AppConfig.settings.customerQuestions;
  private services = AppConfig.settings.services;
  location;

  constructor(
    private departmentService: DepartmentService,
    private router: Router,
    protected alertService: AlertService,
    private servicesService: ServicesService,
    private cdref: ChangeDetectorRef,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private datastoreService: DatastoreService,
    private memberService: MemberService,
    private availabilityService: AvailabilityService
  ) { }

  // Go back to the booking details page
  routerGoBack() {
    // If location is before services then redirect back to locations else redirect back to departments

    this.router.navigate(["/departments"]);

  }

  restartJourney() {
    this.router.navigate(["/departments"]);
  }

  ngOnInit() {
    this.datastoreService.bookingForm = null;
    this.datastoreService.selectedDate = null;
    this.datastoreService.selectedSlot = null;
    this.availabilityService.setSelectedSlot(null);
    this.scrollToTop();
    this.spinner.show();

    // Use this.departmentService.getLocation() if the services are set on the children departments
    // Use this.departmentService.get() if the services are set on the parent department
    this.departmentService.get().then(
      (selectedDepartment) => {
        if (selectedDepartment === undefined) {
          this.restartJourney();
        } else {
          this.selectedDepartment = selectedDepartment;

          // Get the configurable data
          if (this.selectedDepartment.hasOwnProperty("extra")) {
            // Set the title on the login page to the title from the business question
            this.selectedDepartment.extra.hasOwnProperty("services_page_title")
              ? (this.pageTitle = this.selectedDepartment.extra.services_page_title)
              : (this.pageTitle = "Services title not set");
            // Set the extra text on the page to the text taken from the business question
            this.selectedDepartment.extra.hasOwnProperty("services_page_text")
              ? (this.servicePageText = this.selectedDepartment.extra.services_page_text)
              : null;
          }

          this.getServices();
        }
      },
      (err) => {
        this.alertService.error(
          this.translateService.instant("COMMON.GENERAL_ERR")
        );
        this.spinner.hide();
      }
    );
  }

  
  // Get the available services on the selected department
  async getServices() {

    let services = await this.servicesService.getServices(this.selectedDepartment);

    // if(services.length === 1){
    //   // this.selectService(services[0])
    // } else {
      services.forEach(async element => {
        //let childServices = await element.$getChildServices();
  
        let service: any = {};
  
        // service.name = element.name
        // service.description = element.description
  
       // Get the available services on the selected department
       this.servicesService
       .getServices(this.selectedDepartment)
       .then((services) => {
         this.memberService.getMember().then(
           (member) => {
             this.servicesList = services;
             services.forEach((service, index) => {
               service.$getImages().then((image) => {
                 service.image = image[0];
                 this.cdref.detectChanges();
               });
             });
             this.spinner.hide();
           },
           (err) => {
             this.alertService.error(
               this.translateService.instant("COMMON.GENERAL_ERR")
             );
             this.spinner.hide();
           }
         );
       });
  
        this.servicesList.push(element);
        this.spinner.hide();
      });
    // }
  }

  selectService(service) {
    this.selectedService = service;

    this.continue();
  }

  continue() {
    if (this.selectedService) {
      this.servicesService.set(this.selectedService);
      this.router.navigate(["/locations"]);
    } else {
      this.alertService.warn("Please select a service before proceeding");

      this.scrollToTop();
    }
  }

  // scroll to the top of the page
  scrollToTop() {
    window.scroll(0, 0);
  }
}
