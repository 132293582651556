import { Component } from '@angular/core';
import { context } from 'bookingbug-core-js';
import { AppConfig } from './app.config';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { DatastoreService } from './services/datastore.service';
import { AlertService } from './_alert';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Abbott Diabetes Care Booking Service”';
  loadIssue: boolean;
  mode = 'determinate';
  value: number;
  totalPages = 6;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private DataStore: DatastoreService,
    private alertService: AlertService) {
    translate.setDefaultLang('en');

    this.DataStore.notify.subscribe((result) => {
      this.loadIssue = result;
      this.scrollToTop();
      this.alertService.clear();
      this.alertService.error(this.translate.instant('COMMON.LOAD_ISSUE'));
    })
  }

  ngOnInit() {
    // BB Context storage
    context.apiUrl = AppConfig.settings.apiServer.api_url;
    context.apiVersion = AppConfig.settings.apiServer.version;


       // Subscribe to router events to detect navigation changes
       this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        // Update progress bar when navigation ends
        this.updateProgressBar();
      });
    }
  
    // Function to update the progress bar based on the current page
    updateProgressBar() {
      // Get the current page
      const currentPage = this.getCurrentPage();
      // Calculate the progress percentage
      const progress = (currentPage / this.totalPages) * 100;
      // Update the value of the progress bar
      this.value = progress;
    }
  
    // Function to get the index of the current page
    getCurrentPage(): number {
      // Get the current route URL
      const currentUrl = this.route.snapshot.firstChild.url[0].path;
      // Determine the current page based on the route URL
      switch (currentUrl) {
        case 'services':
          return 1;
        case 'locations':
          return 2;
        case 'calendar':
          return 3;
        case 'booking-details':
          return 4;
        case 'summary':
          return 5;
        case 'confirmation':
          return 6;
        default:
          return 1; // Default to the first page (Services) if route not matched
      }
    }

    // storage event to modify localStorage
    //   window.addEventListener('storage', (event) => {
    //     if (event.storageArea == localStorage) {
    //          let token = localStorage.getItem('token_name');
    //          if(token == undefined) { 
    //            // Perform logout
    //            //Navigate to homepage
    //             this.router.navigate(['/departments']); 
    //          }
    //     }
    // });
    // } 
  

  scrollToTop() {
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 10);
  }



}