import { Component, OnInit } from '@angular/core';
import { Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private doc: Document
  ) { }

  ngOnInit() {
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://consent.trustarc.com/v2/notice/k9f1kj';
    
    this.renderer2.appendChild(this.doc.body, s);
  }

  scrollToTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

}
