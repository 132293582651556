import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss']
})
export class CookiePolicyComponent implements OnInit {
  faChevronLeft = faChevronLeft;
  showProdElement: boolean = false;
  showStagingElement: boolean = false;

  constructor(
    private router: Router,
  ) { }
  routerGoBack() {
    this.router.navigate(['/services']);
  }

  ngOnInit() {
    if(environment.production) {
      this.showProdElement = true;
    } else {
      this.showStagingElement = true;
    }
  }

}
