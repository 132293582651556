import { Component, OnInit } from '@angular/core';
import { DatastoreService } from "src/app/services/datastore.service";
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  show: boolean = false;
  showStagingText: boolean = false;
  showSubmenu: number = 0;
  constructor(
    public datastoreService: DatastoreService
  ) { }

  ngOnInit() {
    if(environment.production) {
      this.showStagingText = false;
    } else {
      this.showStagingText = true;
    }
  }

}
