import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { AvailabilityService } from 'src/app/services/jrni/availability.service';
import { AlertService } from 'src/app/_alert';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { DatastoreService } from 'src/app/services/datastore.service';
import { DepartmentService } from 'src/app/services/jrni/department.service';
import { ServicesService } from 'src/app/services/jrni/services.service';
import { ResourcesService } from 'src/app/services/jrni/resources.service';
import { MatCalendar } from '@angular/material';
import { interval } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from "src/app/app.config";

@Injectable({
  providedIn: "root",
})

@Component({
  selector: 'app-mat-calendar',
  templateUrl: './mat-calendar.component.html',
  styleUrls: ['./mat-calendar.component.scss']
})
export class MatCalendarComponent implements OnInit {
  minDate;
  maxDate;
  selectedDate: Date;
  currentDate: Date;
  slots: any[] = [];
  selectedSlot;
  endTime;
  subscription;
  slotDuration;
  availableDays: any;
  selectedLocation: any;
  selectedResource;
  noResource: any;
  selectedMonth: any;
  private core = AppConfig.settings.core;
  private apiServer = AppConfig.settings.apiServer;
  @Input() service: any;
  @Input() location: any;
  @Input() component: any;
  @Output() selectSlotUpdate = new EventEmitter<boolean>();
  loaded: boolean = false;

  @ViewChild(MatCalendar) calendar: MatCalendar<Date>;

  constructor(
    private availabilityService: AvailabilityService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private datastoreService: DatastoreService,
    private departmentService: DepartmentService,
    private servicesService: ServicesService,
    private ElByClassName: ElementRef,
    private http: HttpClient,
    private resourcesService: ResourcesService
  ) { }

  ngOnInit() {
    // Set the current date to today for the calendar to default to today
    this.currentDate = new Date();
    this.selectedDate = moment(this.currentDate).add(1, 'd').toDate();

    // Set max date as yesterday so the user cannot select a date until
    const minAdvTime = new Date();
    // back office is set to 16 minutes, we need 20 minutes so add 4 minutes onto it
    this.minDate = moment(minAdvTime).add(4, 'm').toDate();
    this.maxDate = moment(minAdvTime).add(30, 'd').toDate();
    this.setUp();
  }

  async setUp() {
    this.spinner.show()
    await this.calendarRun();
    this.prepareDatesAfterUpdate();

    if (!this.calendar) {
      setTimeout(() => {
        this.autoSelectSlot();
      }, 0);
    } else {
      this.autoSelectSlot();
    }

    this.spinner.hide()
  }

  autoSelectSlot() {
    if (this.datastoreService.selectedSlot) {
      this.calendar.activeDate = moment(this.datastoreService.selectedSlot).toDate();
      this.calendar.updateTodaysDate();
    } else {
      if (this.slots.length > 0) {
        let firstSlot = this.slots[0];
        this.calendar.activeDate = moment(firstSlot.datetime).toDate();
        this.calendar.updateTodaysDate();
      }
    }
  }


  async calendarRun() {
    this.loaded = false;
    let serviceAvailability;
    // Get the available times for a service
    let location = await this.departmentService.getLocation();

    // get service for selected location
    let selectedLocationService = await this.servicesService.getServices(location);
    // select the first service from the selected location
    let service = selectedLocationService[0];

    // let service = await this.servicesService.get();
    this.service = service;
    this.slotDuration = service.durations[0];
    console.log(`Slot duration ${this.slotDuration}`);

    // redo the max advance date once we have the service
    const minAdvTime = new Date();
    this.maxDate = moment(minAdvTime).add(service.max_advance_period, 's').toDate();

    // If the selected date has been store in datastore then pre populate the selection
    if (this.datastoreService.selectedDate && this.datastoreService.selectedSlot) {
      const tmpDate = moment(this.datastoreService.selectedSlot);
      await this.updateSlots(new Date(tmpDate.format('YYYY-MM-DDTHH:mm:ss')));
      this.selectedSlot = this.datastoreService.selectedSlot;
    } else {
      if (this.availableDays && this.availableDays.length > 0) {
        await this.updateSlots(new Date(this.availableDays[0].date));
      } else {
        await this.updateSlots(new Date());
      }
    }

    let latestDate = moment(this.minDate.toISOString().split('T')[0]);
    serviceAvailability = { days: [] };
    while (latestDate.isBefore(moment(this.maxDate.toISOString().split('T')[0]).add(1, 'd'))) {
      // checks if day is on nonBookableDays days list
      if (latestDate.isoWeekday() !== 0) {  // could let us exclude days, currently does not
        serviceAvailability.days.push({ date: moment(latestDate).format("YYYY-MM-DD"), spaces: 1, fully_booked: false });
      } else {
        serviceAvailability.days.push({ date: moment(latestDate).format("YYYY-MM-DD"), spaces: 0, fully_booked: true });
      }
      latestDate = latestDate.add(1, 'd');
    }
    await this.finCalanderRun(serviceAvailability); // set it with placeholder
    if (this.component) {
      serviceAvailability = await this.availabilityService.requestAvailableDays(location, service, this.minDate.toISOString().split('T')[0], this.maxDate.toISOString().split('T')[0]);
    } else {
      serviceAvailability = await this.availabilityService.requestAvailableDaysService(location, service, this.minDate.toISOString().split('T')[0], this.maxDate.toISOString().split('T')[0]);
    }
    await this.finCalanderRun(serviceAvailability); //set it with actual
    // this.calendar.updateTodaysDate(); // refreshes the calander so it shows the availibility
  }

  prepareDatesAfterUpdate() {
    const minAdvTime = new Date();
    this.maxDate = moment(minAdvTime).add(this.service.max_advance_period, 's').toDate();
  }

  async finCalanderRun(serviceAvailability: any) {
    // Set the available times so we do have to keep sendisng the same request
    await this.availabilityService.setAvailableDays(serviceAvailability.days);

    // If service availability contains data then filter the days that are available
    if (serviceAvailability.days && serviceAvailability.days.length > 0) {
      // Get the available days
      this.availableDays = serviceAvailability.days.filter(day => day.spaces > 0);
      if (!this.datastoreService.selectedDate && this.availableDays.length > 0) {
        const firstDay = moment(this.availableDays[0].date);
        const selectedDate = moment(this.selectedDate);
        if (!firstDay.isSame(selectedDate, 'd')) {
          const tmpDate = new Date(firstDay.format('YYYY-MM-DDTHH:mm:ss'));
          this.datastoreService.selectedDate = {
            datetime: tmpDate
          };
          await this.updateSlots(tmpDate);
        }
      }
    } else {
      this.alertService.error(this.translateService.instant('COMMON.NO_AVAILABILITY'));
    }

  }

  ngAfterViewInit() {
    const source = interval(500); // repeatedly check to see if we need to add avaiable/unavaiable to the aria (which is used by screen readers)
    const text = 'Your Text Here';
    this.subscription = source.subscribe(val => {
      let dateElement: any = (<HTMLElement>this.ElByClassName.nativeElement).querySelectorAll(
        '.mat-calendar-body-cell'
      );
      for (let i = 0; i < dateElement.length; i++) {
        dateElement[i].role = "gridcell"
        const old = dateElement[i].ariaLabel;
        if (old !== old.replace(" unavailable", "").replace(" available", "")) {
          ;// allready up to date so dont do it all over again again
          return;
        }
        dateElement[i].ariaLabel = old.replace(" unavailable", "").replace(" available", "") + ' available';
      }
      dateElement = (<HTMLElement>this.ElByClassName.nativeElement).querySelectorAll(
        '.mat-calendar-body-disabled'
      );
      for (let i = 0; i < dateElement.length; i++) {
        const old = dateElement[i].ariaLabel;

        dateElement[i].ariaLabel = old.replace(" unavailable", "").replace(" available", "") + ' unavailable';
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // Check if the date is unavailable and if so return false
  unavailableDates = (date: Date): boolean => {
    const momentDate = moment(date);

    if (this.availableDays) {
      return this.availableDays.some(day => momentDate.isSame(day.date));
    } else {
      return true;
    }
  }

  // Date selected on calendar
  async updateSlots(date, spin = false) {
    if (spin) {
      this.spinner.show()
    }
    this.loaded = false;
    // console.log("updateSlots")
    // add two hours onto the date so when we convert with iso it always keeps the same date
    date.setHours(date.getHours() + 2);

    // Update the selected date
    this.selectedDate = date;

    // slice time off
    const isoTime = date.toISOString().slice(0, -1).split('T')[0];


    let location = await this.departmentService.getLocation();
    // get service for selected location
    let selectedLocationService = await this.servicesService.getServices(location);
    // select the first service from the selected location
    let selectedServiceId = selectedLocationService[0].id;

    let selectedLocationResources = await this.resourcesService.getResources(location);

    if (selectedLocationResources.length == 0) {
      console.log("No resources");
      this.noResource = true;
      return;
    }

    let selectedResources = selectedLocationResources.map(resource => resource.id);

    const params = {
      'resource_ids': selectedResources.join(","),
      'service_id': selectedServiceId,
      'date': isoTime,
      'end_date': isoTime,
    };

    // Only add slots with availability
    const available = await this.availabilityService.getAvailableSlots(location, params);
    this.slots = [];
    // get the days slots
    for (let staff of available['_embedded'].events) {
      for (let timeSlot of staff.times) {
        timeSlot.person_id = staff.person_id;
        timeSlot.resource_id = staff.resource_id;
        if (timeSlot.avail == 1) {
          this.slots.push(timeSlot);
        }
      }
    }

    // slots api to find blocks/bookings
    if (this.slots.length > 0) {
      let slotParams = {
        'start': isoTime,
        'end': isoTime,
        'resources': selectedResources.join(",")
      }
      const res = await this.availabilityService.getSlots(location, slotParams);
      if (res && res['_embedded']['slots'].length > 0) {
        res['_embedded']['slots'].forEach((takenSlot: any) => {
          if (takenSlot.spaces_blocked > 0) {
            let slotToRemove = this.slots.filter((slot: any) => slot.datetime == takenSlot.datetime && slot.resource_id == takenSlot.resource_id);
            // console.log("slot to remove:", slotToRemove);
            slotToRemove.forEach((slot: any) => {
              slot.avail = 0;
              slot.taken = true;
            });
          }
        });
      }
    }

    // remove the slots that was processed late by the /slots request
    this.slots = this.slots.filter((slot: any) => slot.avail == 1);

    // Remove dupes from different staff
    this.slots = this.slots.filter((slot, index, self) =>
      index === self.findIndex((t) => (
        t.datetime === slot.datetime
      ))
    )

    // Remove slots if they are past the minDate (20 minutes before any booking)
    this.slots = this.slots.filter(x => new Date(x.datetime) > this.minDate)


    // sort the slots in order
    this.slots.sort((a, b) => a.time - b.time);

    this.loaded = true
    if (spin) {
      this.spinner.hide()
    }
  }

  // Set the slot
  selectSlot(slot) {
    this.selectedSlot = slot;
    this.availabilityService.setSelectedSlot(slot);
    this.datastoreService.selectedSlot = slot;
    this.selectSlotUpdate.emit(true);
    // set end slot time
    var endSlot = slot.datetime
    this.endTime = moment(endSlot).add(this.slotDuration, 'm').toDate();
    this.availabilityService.setEndSlot(this.endTime)
  }
}



