import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/jrni/api.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-url-finder',
  templateUrl: './url-finder.component.html',
  styleUrls: ['./url-finder.component.scss']
})
export class UrlFinderComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private spinner: NgxSpinnerService
  ) { }

  async ngOnInit() {
    let target = this.router.url.trim();
    if (target.charAt(0) == '/') {
      target = target.substring(1);
    }
    this.spinner.show();
    try {
      await this.loadUrl(target);
      this.spinner.hide();
    }
    catch (err) {
      console.error(err);
    }
  }

  async loadUrl(target:string) {
    const dest:any = await this.apiService.getShortenedUrl(target);
    window.location.assign(dest.destination);

  }

}
