import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DepartmentService } from "src/app/services/jrni/department.service";
import { ServicesService } from "src/app/services/jrni/services.service";
import { AvailabilityService } from "src/app/services/jrni/availability.service";
import { BasketService } from "src/app/services/jrni/basket.service";
import { MemberService } from "src/app/services/jrni/member.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { DatastoreService } from "src/app/services/datastore.service";
import { AppConfig } from "src/app/app.config";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AlertService } from "src/app/_alert";
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from "src/app/services/jrni/api.service";
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ResourcesService } from "src/app/services/jrni/resources.service";
@Component({
  selector: "app-summary",
  templateUrl: "./summary.component.html",
  styleUrls: ["./summary.component.scss"],
})
export class SummaryComponent implements OnInit {
  selectedDepartment;
  parentDepartment;
  selectedLocation;
  selectedService;
  pageTitle: string;
  summaryPageText: string;
  selectedSlot;
  endSlot;
  termsAndConditions;
  submitted = false;
  items;
  member;
  client;
  address;
  lat;
  long;
  bookingQuestions = [];
  bookingAnswers = [];
  displayTnCs: boolean;
  bookingQuestionsConfig = AppConfig.settings.bookingQuestions;
  bookingSummaryData = [];
  total: number = 0;
  extraDetailsPresent = false;
  slotAvailable = true;
  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;
  nr: any = window['newrelic'];
  selectedResource;

  constructor(
    private router: Router,
    private departmentService: DepartmentService,
    private servicesService: ServicesService,
    private availabilityService: AvailabilityService,
    private basketService: BasketService,
    private memberService: MemberService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private datastoreService: DatastoreService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private apiService: ApiService,
    private resourcesService: ResourcesService
  ) { }

  addressEnabled = AppConfig.settings.addressApi.enabled;

  routerGoBack() {
    this.router.navigate(["/booking-details"]);
  }

  restartJourney() {
    this.router.navigate(["/departments"]);
  }

  async ngOnInit() {
    this.scrollToTop();
    this.alertService.clear();

    this.parentDepartment = this.datastoreService.parentDepartment;
    // this.selectedDepartment = this.datastoreService.selectedDepartment;

    this.selectedDepartment = await this.departmentService.getLocation();


    this.servicesService.get().then(async (company) => {
      if (company === undefined) {
        // company not found, restart
        this.restartJourney();
      } else {
        // Set the questions and answer
        this.bookingQuestions = this.datastoreService.bookingQuestions;
        this.client = this.datastoreService.customerDetailsForm.value;

        this.selectedLocation = this.selectedDepartment;

        this.selectedLocation?.$getAddress().then((res) => {
          this.address = res;
          this.datastoreService.address = res;
        });
        // Get the selected slot
        if (this.datastoreService.selectedSlot) {
          this.availabilityService.getSelectedSlot().then((slot) => {
            this.selectedSlot = slot;
            this.availabilityService.getEndSlot().then((slot) => {
              this.endSlot = slot;
            });
            this.getResource(this.selectedSlot.resource_id).then((resource) => {
              this.resourcesService.set(resource);
              this.selectedResource =  resource.name;
            });
          });
        }


        if (this.parentDepartment) {
          // Get the configurable data
          if (this.parentDepartment.hasOwnProperty("extra")) {
            // Set the title on the login page to the title from the business question
            this.parentDepartment.extra.hasOwnProperty("summary_page_title")
              ? (this.pageTitle = this.parentDepartment.extra.summary_page_title)
              : (this.pageTitle = "Summary title not set");
            // Set the extra text on the page to the text taken from the business question
            this.parentDepartment.extra.hasOwnProperty("summary_page_text")
              ? (this.summaryPageText = this.parentDepartment.extra.summary_page_text)
              : null;
            // Tncs
            this.getTermsAndConditions();
          }

          // // Use this.departmentService.getLocation() if the services are set on the children departments
          // // Use this.departmentService.get() if the services are set on the parent department
          // this.departmentService.getLocation().then((location) => {
          //   this.selectedLocation = location;
          // });

          // Get the selected service

          let location = await this.departmentService.getLocation();
          // get service for selected location
          let selectedLocationService = await this.servicesService.getServices(location);
          // select the first service from the selected location
          let selectedService = selectedLocationService[0];

          // this.servicesService.get().then((service) => {
          this.selectedService = selectedService;

          this.datastoreService.bookingQuestions.forEach(question => {
            if (this.datastoreService.bookingForm.value[question['id']]) {
              this.bookingSummaryData.push({
                name: question.name,
                answer: this.datastoreService.bookingForm.value[question.id],
                detail_type: question.detail_type,
                price: question.price
              });
            }
          });
          // Check if the questions contain any other purchase details
          const extraDetails = this.bookingSummaryData.filter(elem => elem.detail_type !== 'check-price');
          extraDetails.length > 0 ? this.extraDetailsPresent = true : this.extraDetailsPresent = false;
          // Calculate purchase total
          const paidItems = this.bookingSummaryData.filter(item => item.detail_type === 'check-price');
          this.total = paidItems
            .map((items) => items.price)
            .reduce((a, b) => a + b, 0) + this.selectedService?.price;
          // });

          // Get the selected slot
          this.availabilityService.getSelectedSlot().then((slot) => {
            this.selectedSlot = slot;
          });
        } else {
          this.restartJourney();
        }
      }
    });
  }
  async getResource(resourceId) {
    return this.resourcesService.getResources(this.selectedLocation).then((resources) => {
      let foundResource
      for (const resource of resources) {
        if (resource.id === resourceId) {
          foundResource = resource;
          break; 
        }
      }
      return foundResource;
    });
  }

  getTermsAndConditions() {
    if (this.parentDepartment.hasOwnProperty("extra")) {
      this.displayTnCs = true;
      // Check if the terms and conditions are on/off
      if (
        this.parentDepartment.extra.hasOwnProperty("display_tncs") &&
        this.parentDepartment.extra.display_tncs.toLowerCase() === "yes"
      ) {
        this.termsAndConditions = "";
        // Loop through all the extra pre defined fields
        const extraQuestions = this.parentDepartment.extra;
        for (const key in extraQuestions) {
          if (extraQuestions.hasOwnProperty(key)) {
            const element = extraQuestions[key];
            if (key.indexOf("terms_and_conditions") >= 0) {
              if (!this.termsAndConditions) {
                this.termsAndConditions += element;
              } else {
                this.termsAndConditions += " " + element;
              }
            }
          }
        }
      } else {
        this.displayTnCs = false;
      }
    }
  }

  // create client
  async createClient() {
    let addressFirstPart = this.datastoreService.address1
    // if (this.addressEnabled) {
    //   if (
    //     this.datastoreService.customerDetailsForm.value.selectedAddress.address
    //       .BUILDING_NUMBER &&
    //     this.datastoreService.customerDetailsForm.value.selectedAddress.address
    //       .ORGANISATION_NAME
    //   ) {
    //     addressFirstPart = `${this.datastoreService.customerDetailsForm.value.selectedAddress.address.ORGANISATION_NAME}, ${this.datastoreService.customerDetailsForm.value.selectedAddress.address.BUILDING_NAME}, ${this.datastoreService.customerDetailsForm.value.selectedAddress.address.BUILDING_NUMBER},`;
    //   } else {
    //     if (
    //       this.datastoreService.customerDetailsForm.value.selectedAddress.address
    //         .BUILDING_NUMBER &&
    //       this.datastoreService.customerDetailsForm.value.selectedAddress.address
    //         .ORGANISATION_NAME
    //     ) {
    //       addressFirstPart = `${this.datastoreService.customerDetailsForm.value.selectedAddress.address.BUILDING_NUMBER}, ${this.datastoreService.customerDetailsForm.value.selectedAddress.address.ORGANISATION}`;
    //     } else if (this.datastoreService.customerDetailsForm.value.selectedAddress.address
    //       .BUILDING_NUMBER &&
    //       this.datastoreService.customerDetailsForm.value.selectedAddress.address
    //         .ORGANISATION
    //     ) {
    //       addressFirstPart = `${this.datastoreService.customerDetailsForm.value.selectedAddress.address.BUILDING_NUMBER}, ${this.datastoreService.customerDetailsForm.value.selectedAddress.address.ORGANISATION_NAME}`;
    //     } else if (
    //       this.datastoreService.customerDetailsForm.value.selectedAddress.address
    //         .BUILDING_NAME &&
    //       this.datastoreService.customerDetailsForm.value.selectedAddress.address
    //         .ORGANISATION_NAME
    //     ) {
    //       addressFirstPart = `${this.datastoreService.customerDetailsForm.value.selectedAddress.address.ORGANISATION_NAME}, ${this.datastoreService.customerDetailsForm.value.selectedAddress.address.BUILDING_NAME}`;
    //     } else if (
    //       this.datastoreService.customerDetailsForm.value.selectedAddress.address
    //         .BUILDING_NAME &&
    //       this.datastoreService.customerDetailsForm.value.selectedAddress.address
    //         .ORGANISATION
    //     ) {
    //       addressFirstPart = `${this.datastoreService.customerDetailsForm.value.selectedAddress.address.ORGANISATION}, ${this.datastoreService.customerDetailsForm.value.selectedAddress.address.BUILDING_NAME}`;
    //     } else {  
    //       if (
    //         this.datastoreService.customerDetailsForm.value.selectedAddress
    //           .address.BUILDING_NUMBER
    //       ) {
    //         addressFirstPart = this.datastoreService.customerDetailsForm.value
    //           .selectedAddress.address.BUILDING_NUMBER;
    //       } else if (
    //         this.datastoreService.customerDetailsForm.value.selectedAddress
    //           .address.BUILDING_NAME
    //       ) {
    //         addressFirstPart = this.datastoreService.customerDetailsForm.value
    //           .selectedAddress.address.BUILDING_NAME;
    //       } else if (
    //         this.datastoreService.customerDetailsForm.value.selectedAddress
    //           .address.ORGANISATION
    //       ) {
    //         addressFirstPart = this.datastoreService.customerDetailsForm.value
    //           .selectedAddress.address.ORGANISATION;
    //       } else {
    //         addressFirstPart = this.datastoreService.customerDetailsForm.value
    //           .selectedAddress.address.ORGANISATION_NAME;
    //       }
    //     }
    //   }
    // }

    let customerQs = [];

    // For each question in the form check if the customer question exists and if so add to customerAnswers []
    for (const control in this.datastoreService.customerDetailsForm.value) {
      if (this.datastoreService.customerDetailsForm.value.hasOwnProperty(control)) {
        const element = this.datastoreService.customerDetailsForm.value[control];
        const question = this.datastoreService.customerQuestions.filter(question => question.id.toString() === control);
        let q = {};
        if (question.length > 0 && element !== "") {
          q = {
            id: question[0].id,
            answer: element
          }
          customerQs.push(q);
        }
      }
    }
    // for(let a of this.datastoreService.wasteLimitAnswers){
    //   this.bookingAnswers.push(a)
    // }
    this.datastoreService.bookingAnswers = this.bookingAnswers;
    const customerDetailsValue = this.datastoreService.customerDetailsForm.value;

    // DPI: POST_TOWN
    // LPI: TOWN_NAME
    let town = '';
    let postcode = '';
    if (this.addressEnabled) {
      town = customerDetailsValue.selectedAddress.address.POST_TOWN ?
        customerDetailsValue.selectedAddress.address.POST_TOWN :
        customerDetailsValue.selectedAddress.address.TOWN_NAME;

      postcode = customerDetailsValue.postcode
        .trim()
        .replace(" ", "")

      var custodian_codes = [5060, 5480, 5750, 5780]; //custodian_codes in east london
      const e = customerDetailsValue.selectedAddress.address;
      const matchingCodes = custodian_codes.filter(code => {
        if (e.LOCAL_CUSTODIAN_CODE === code) {
          return true;
        }
      });
      if (!(matchingCodes.length > 0)) {
        // this.bookingAnswers.push({ //addmin question about if addess is in kent or not
        //   id: 458,
        //   answer: 1,
        // });
      }
    }

    const params = {
      first_name: customerDetailsValue.first_name,
      last_name: customerDetailsValue.last_name,
      email: customerDetailsValue.email_address,
      mobile: customerDetailsValue.mobile_number,
      address4: town.toLowerCase(),
      postcode: postcode,
      client_type: "contact",
      questions: customerQs
    };

    if (this.addressEnabled) {
      const clientAddress = this.datastoreService.customerDetailsForm.value.selectedAddress;

      const addressParts = clientAddress.address.ADDRESS.split(',');
      const addressLine1 = addressParts.shift().toLowerCase().trim();
      const addressLine2 = addressParts.shift().toLowerCase().trim();
      // const addressLine3 = addressParts.shift().toLowerCase().trim();
      const addressLine3 = `${(clientAddress.address.hasOwnProperty('BUILDING_NUMBER')) ? clientAddress.address.BUILDING_NUMBER + ' ' : ''}${(clientAddress.address.hasOwnProperty('THOROUGHFARE_NAME')) ? clientAddress.address.THOROUGHFARE_NAME : ''}`;

      params['address1'] = addressLine1;
      params['address2'] = addressLine2;
      params['address3'] = addressLine3.toLowerCase();
    }

    try {
      const clientRes = await this.selectedDepartment.$post("client", {}, params);
      if (clientRes) {
        this.datastoreService.client = clientRes;
        return true;
      }
      else {
        return false;
      }
    }
    catch (err) {
      console.error(err);
      this.alertService.error(this.translateService.instant('COMMON.LOAD_ISSUE'));
      if (this.nr) {
        this.nr.addPageAction('ELWAError', { client: 'East London Waste', host: AppConfig.settings.apiServer, errorMessage: JSON.stringify(err), errorType: 'CREATE_CLIENT' });
      }
      this.spinner.hide();
      return false;
    }
  }

  async addItem() {
    // Get the selected slot from the previous page
    const selectedSlot = await this.availabilityService.getSelectedSlot();
  
    // Get the booking answers and add them to an array ready to add to basket
    for (var key in this.datastoreService.bookingForm.value) {
      this.bookingAnswers.push({
        id: key,
        answer: this.datastoreService.bookingForm.value[key],
      });
    }
    this.datastoreService.bookingAnswers = this.bookingAnswers;
  
    let selectedResource = undefined;

    if (selectedSlot.resource_id) {
      selectedResource = selectedSlot.resource_id;
    }
    
    // Add item to the basket
    const res = await this.basketService.addItem(
      this.selectedLocation,
      this.selectedService.id,
      this.selectedService.durations[0],
      selectedSlot.datetime,
      this.bookingAnswers,
      selectedResource
    )

    const questionWithId5AndYesAnswer = this.bookingAnswers.filter(item => item.id === "5" && item.answer === "Yes");
    if (questionWithId5AndYesAnswer.length > 0) {
      await this.basketService.addItem(
        this.selectedLocation,
        this.selectedService.id,
        this.selectedService.durations[0],
        selectedSlot.datetime,
        this.bookingAnswers,
        selectedResource
      );
      
      // console.log("Found question with ID 5 and answer Yes:", questionWithId5AndYesAnswer);
    } 
  
    // Handle errors if addItem() fails
    if (res === "failed") {
      // If conflict/409
      this.alertService.error(this.translateService.instant('SUMMARY.INVALID_SLOT'));
      this.spinner.hide();
      this.scrollToTop();
    }
  }

  async startCheckout() {
    this.spinner.show();

    // Check if selected slot is still available
    // let slotAvailable: boolean
    // await this.apiService.checkSlotAvailability(this.selectedDepartment, this.selectedSlot.datetime).then((res: any) => {
    //   slotAvailable = res.available;
    // })

    // if (slotAvailable === false) {
    // If unavailable, then alert user
    //   this.alertService.error(this.translateService.instant('SUMMARY.INVALID_SLOT'));
    //   this.scrollToTop()
    //   this.slotAvailable = false;
    //   this.spinner.hide();
    // } else {
    // If available then checkout
    this.checkout()
    // }
  }

  backToCalendar() {
    this.router.navigate(["/calendar"]);
  }

  async checkout() {
    this.alertService.clear();
    this.submitted = true;
    const noNotifications = false;

    const clientCreated = await this.createClient();
    if (clientCreated) {
      await this.addItem();

      // set member as the client,if the login is not required
      if (
        this.departmentService.selectedDepartment.hasOwnProperty("extra") &&
        this.departmentService.selectedDepartment.extra.hasOwnProperty(
          "login_required"
        )
      ) {
        this.departmentService.selectedDepartment.extra.login_required.toLowerCase() ===
          "yes"
          ? (this.member = await this.memberService.getMember())
          : (this.member = await this.datastoreService.client);
      } else {
        this.member = await this.datastoreService.client;
      }

      await this.basketService
        .checkoutBasket(this.selectedLocation, this.member, noNotifications)
        .then(async (res) => {
          // Get the purchase
          // this.basketService.getPurchase().then((purchase) => {
          //   purchase.$get("bookings").then(async (bookings) => {
          //     const formData = new FormData();
          //   });
          // });

          // update booking limit
          // if (!this.datastoreService.isCommercialBooking) {
          //   const slot: any = await this.availabilityService.getSelectedSlot();
          //   const slotDateTime = slot.datetime;
          //   await this.apiService.updateBookingLimit(this.datastoreService.parentDepartment, this.datastoreService.address1, slotDateTime);
          //   if (this.datastoreService.isRestrictedBooking) {
          //     await this.apiService.updateBookingLimit(this.datastoreService.parentDepartment, `${this.datastoreService.address1}_RESTRICTED`, slotDateTime);
          //   }
          //   if (this.datastoreService.isRestrictedAsbestosBooking) {
          //     await this.apiService.updateBookingLimit(this.datastoreService.selectedDepartment, this.datastoreService.restrictedAsbestosKey, slotDateTime);
          //   }
          // }

          this.router.navigate(["/confirmation"]);
        }).catch((error: HttpErrorResponse) => {
          if (error['response'] && error['response'].status === 409) {
            this.alertService.error(this.translateService.instant('SUMMARY.INVALID_SLOT'));
          }
          else {
            this.alertService.error(this.translateService.instant('COMMON.LOAD_ISSUE'));
          }
          if (this.nr) {
            this.nr.addPageAction('abbottError', { client: 'Abbott', host: AppConfig.settings.apiServer, errorMessage: JSON.stringify(error), errorType: 'CHECKOUT' });
          }
          this.spinner.hide();
          this.scrollToTop();
        });
    }
    else {
      this.alertService.error(this.translateService.instant('COMMON.LOAD_ISSUE'));
      this.spinner.hide();
      this.scrollToTop();
    }
  }

  // Scroll to the top of the page
  scrollToTop() {
    window.scroll(0, 0);
  }
}
