// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'staging',
  apiServer: "https://m5nius1kad.execute-api.eu-west-2.amazonaws.com/1",
  clientToken: "7127bb3968a9da3dcc74070054bd585dbfcc18672a7b9bf378749fc6e0e26bdc",
  urlShortener: "https://pbi64hzub7uwkfo3xdo76s2roq0gokaq.lambda-url.eu-west-1.on.aws/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
