import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfig } from "src/app/app.config";
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: "root",
})
export class AvailabilityService {
  private core = AppConfig.settings.core;
  private apiServer = AppConfig.settings.apiServer;
  selectedSlot;
  availableTimesService;
  availableDaysService;
  endSlot;
  selectedResource;

  constructor(private http: HttpClient) { }

  async getSlots(location, params) {
    const headers = { 
      'Content-Type': 'application/json',
      'Clienttoken': environment.clientToken
     };
      let url = `${environment.apiServer}/company/${location.id}/slots`;
      let join = '?';
      if (params.start) {
        url = `${url}${join}start=${params.start}`;
        join = '&';
      }
      if (params.end) {
        url = `${url}${join}end=${params.end}`;
        join = '&';
      }
      // if (params.serviceid) {
      //   url = `${url}${join}service_id=${params.serviceid}`;
      //   join = '&';
      // }

      // https://m5nius1kad.execute-api.eu-west-2.amazonaws.com/1/company/37002/slots?start=2024-03-26&end=2024-03-26

    // /company/{company}/slots
    return await this.http.get(`${url}`, { headers }).toPromise();
  }

  async getAvailableSlots(location, params) {
    const headers: HttpHeaders = new HttpHeaders({
      "App-Id": this.core.app_id,
      "App-Key": this.core.app_key,
    });

    const apiUrl = `${this.apiServer.api_url}/api/v5/${location.id}/time_data`;
    return await this.http.get(apiUrl, { headers, params }).toPromise();
  }

  async setSelectedSlot(slot) {
    this.selectedSlot = slot;
    if(slot) {
      this.selectedResource = slot.resource_id;
    }
  }
  async setEndSlot(slot) {
    this.endSlot = slot;
  }

  async getSelectedSlot() {
    return this.selectedSlot;
  }
  async getEndSlot() {
    return this.endSlot;
  }
  async getSelectedResource() {
    return this.selectedResource;
  }

  async requestAvailibleTimes(location, service, today, maxDate) {
    const params = {
      company_id: location.id,
      service_id: service.id,
      start_date: today,
      end_date: maxDate,
    };
    const baseResource = await location.$get("times", params);
    return baseResource.times;
  }

  async setAvailableTimes(availableTimes) {
    this.availableTimesService = availableTimes;
  }
  
  async getTimes() {
    return this.availableTimesService;
  }

  async requestAvailableDays(location, service, today, maxDate) {
    // Use below if the services are set on the parent department
    // const services = await service.$getChildServices();
    // const childService = services.filter(service => service.company_id === location.id);
    // return await childService[0].$get('days', { company_id: location.id, date: today, edate: maxDate });

    //Use below if the services are set on the children departments
    return await service.$get("days", {
      company_id: location.id,
      date: today,
      edate: maxDate,
    });
  }

  async setAvailableDays(availableDays) {
    this.availableDaysService = availableDays;
  }

  async getDays() {
    return this.availableDaysService;
  }

  async requestAvailableDaysService(location, service, today, maxDate) {
    return await service.$get("days", {
      company_id: location.id,
      date: today,
      edate: maxDate,
    });
  }
}
