import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DepartmentService } from 'src/app/services/jrni/department.service';
import { LeafletMapComponent } from 'src/app/leaflet-map/leaflet-map.component';
import { AlertService } from 'src/app/_alert';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { DatastoreService } from 'src/app/services/datastore.service';
import { AvailabilityService } from 'src/app/services/jrni/availability.service';
import { ApiService } from 'src/app/services/jrni/api.service';
import { ServicesService } from 'src/app/services/jrni/services.service';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {

  parentDepartment;
  selectedDepartment;
  pageTitle;
  locationPageText;
  locations;
  searchCode;
  isCodeMatched: boolean;
  searched = false;
  submittedCode;
  geoLookupString;
  selectedLocation;
  mapsRequired: boolean;
  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;
  disabledDiv: boolean = false;
  lastOpenedCompany: any;
  searchInput: any;
  searchCounter: number = 0;
  largestSearchCounter: number = 0;
  osOptions: any;
  latlng: any;

  constructor(
    private router: Router,
    private departmentService: DepartmentService,
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    private datastoreService: DatastoreService,
    private availabilityService: AvailabilityService,
    private servicesService: ServicesService,
    private changeDetector: ChangeDetectorRef,
    private apiService: ApiService
  ) { }

  routerGoBack() {
    this.router.navigate(['/services']);
  }

  restartJourney() {
    this.router.navigate(['/departments']);
  }

  ngOnInit() {
    this.scrollToTop();
    this.spinner.show();

    // Use this.departmentService.getLocation() if the services are set on the children departments
    // Use this.departmentService.get() if the services are set on the parent department
    this.departmentService.get().then(async selectedDepartment => {
      if (selectedDepartment === undefined) {
        this.restartJourney();
      } else {
        this.selectedDepartment = selectedDepartment;
        // const service = await this.servicesService.get();

        // const location = await this.departmentService.searchCompanies();
        // console.log(location)

        // Get the configurable data
        if (this.selectedDepartment.hasOwnProperty('extra')) {
          // Set the title on the login page to the title from the business question
          this.selectedDepartment.extra.hasOwnProperty('location_page_title') ? this.pageTitle = this.selectedDepartment.extra.location_page_title : this.pageTitle = "Location title not set";
          // Set the extra text on the page to the text taken from the business question
          this.selectedDepartment.extra.hasOwnProperty('location_page_text') ? this.locationPageText = this.selectedDepartment.extra.location_page_text : null;
          // Check if maps are required
          this.selectedDepartment.extra.hasOwnProperty('maps') ? this.selectedDepartment.extra.maps.toLowerCase() === 'disabled' ? this.mapsRequired = false : this.mapsRequired = true : null;
        }

        // Get the children departments (locations) from the selected department
        this.departmentService.getSelectedDepartmentChildren().then(locations => {
          this.departmentService.setLocationList(locations);
          this.locations = locations;
          this.datastoreService.locations = locations;
          // this.skipLocationPage();
          this.spinner.hide();
        }, err => {
          this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'))
          this.spinner.hide();
        });
      }
    }, err => {
      console.log(err);
      this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'))
      this.spinner.hide();
    });
  }

  submitCode() {
    // console.log('searchCode:', this.searchCode);
    // console.log('submittedCode:', this.submittedCode);

    this.submittedCode = this.submittedCode.replace(/\s/g, "");

    this.disabledDiv = this.submittedCode.length > 0;

    this.locations = this.datastoreService.locations; // reset list of companies from original
    this.locations = this.locations.filter(company => { // loop over companies
      if (company.extra.clinic_code) { // if the company has a clinic code
        return company.extra.clinic_code.toLowerCase() === this.submittedCode.toLowerCase(); // and that clinic code contains our submitted code
      }
      return false;
    });
    this.lastOpenedCompany = undefined;
    if (this.locations.length > 0) {
      this.expandGeoCompany(this.locations[0]);
    }

    this.searched = true;
  }

  onInputChange(inputValue: string) {
    if (!inputValue.trim()) {
      this.locations = this.datastoreService.locations;
      this.searched = false;
    }
  }

  expandGeoCompany(location: any) {
    this.datastoreService.latitude = location.address.lat;
    this.datastoreService.longitude = location.address.long;
    if (this.lastOpenedCompany) {
      let previousComapny = this.locations.filter(company => company.id === this.lastOpenedCompany.id);
      previousComapny[0]["opened"] = false;
    }
    let selectedCompany = this.locations.filter(company => company.id === location.id);
    selectedCompany[0]["opened"] = true;
    this.lastOpenedCompany = location;
    this.datastoreService.onmapUpdate(true);
  }

  async geoAutocomplete(event: any) {
    if (event.key === "Enter") {
      // this.osSearch(this.osOptions[0]);
    } else {
      this.datastoreService.searchData = undefined; // reset our stored version of the search
      if (this.geoLookupString) {
        if (this.geoLookupString && this.geoLookupString.length >= 1) {
          this.searchInput = document.getElementById("geo-input") as HTMLInputElement;
          // if (this.datastoreService.config?.maps?.keys.hasOwnProperty("OS")) {
          this.searchCounter++;
          const myCount = this.searchCounter;
          const result = await this.apiService.osGetNameData(this.geoLookupString, myCount);
          if (result.n > this.largestSearchCounter) { // we use this to make sure only the latest search gets used
            this.largestSearchCounter = result.n;
            this.osOptions = result.nameData;
          } else {
            // console.log("ejected ", result, " because ", this.largestSearchCounter)
          }
          // } else {
          //   // this.autocomplete = new google.maps.places.Autocomplete(this.searchInput, this.autocompleteOption);
          // }
        } else {
          // Clear search input when geoLookupString is empty
          this.searchInput.value = "";
          this.datastoreService.searchData = undefined;
        }
      }
    }
  }

  async osSearch(osData: any) {
    this.spinner.show();
    this.searched = false;
    this.datastoreService.osData = osData;
    this.locations = this.datastoreService.locations;
    const converted: any = await this.apiService.coordConvertEWtoLL(osData.GAZETTEER_ENTRY.GEOMETRY_X, osData.GAZETTEER_ENTRY.GEOMETRY_Y)
    this.latlng = {
      lat: parseFloat(converted.LATITUDE),
      lng: parseFloat(converted.LONGITUDE),
    };

    // this.getEvents(this.latlng.lat, this.latlng.lng, this.searchRadius, this.filters);
    const result: any = await this.apiService.getNearestCompanies(JSON.stringify(this.latlng), 37001);
    this.locations = result.companies;
    this.lastOpenedCompany = undefined;

    if (this.locations.length > 0) {
      this.expandGeoCompany(this.locations[0]);
    }

    this.searched = true;
    this.osOptions = null
    this.spinner.hide();
  }

  // If there is only one location then skip over the location page
  skipLocationPage() {
    if (this.locations.length === 1) {
      this.selectedLocation = this.locations[0];
      this.continue(undefined);
    }
  }

  selectLocation(location) {
    // get the location from datastore so we know its one that hasnt been altered and lost links
    let selectedLocation = this.datastoreService.locations.filter(loc => loc.id == location.id)[0];
    this.selectedLocation = selectedLocation;
    this.continue(selectedLocation)
  }

  continue(location) {
    if (location) {
      this.departmentService.setLocation(location);
      this.router.navigate(['/calendar']);
    } else {
      this.alertService.warn("Please select a location before proceeding");
      this.scrollToTop();
    }

    // Use this.departmentService.getLocation() if the services are set on the children departments
    // Use this.departmentService.get() if the services are set on the parent department
    // this.departmentService.get().then((location) => {
    //   if (this.selectedLocation) {
    //     if (location && location !== this.selectedLocation) {
    //       this.datastoreService.selectedDate = null;
    //       this.availabilityService.setSelectedSlot(null);
    //     }
    //     this.departmentService.setLocation(this.selectedLocation);
    //     this.router.navigate(['/calendar']);
    //   } else {
    //     this.alertService.warn("Please select a location before proceeding");
    //     this.scrollToTop();
    //   }
    // });
  }

  // scroll to the top of the page
  scrollToTop() {
    window.scroll(0, 0);
  }
}
