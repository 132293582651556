import { Injectable } from '@angular/core';
import { BBService } from 'bookingbug-core-js';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DatastoreService {

  constructor() { }

  public osData;
  
  public searchData;

  public config;

  public parentDepartment;

  public selectedDepartment;

  public bookingQuestions = [];

  public bookingForm;

  public selectedDate;

  public selectedSlot;

  public locations = [];

  public bookingAnswers;

  public customerDetailsForm;

  public client;

  public address;

  public latitude;

  public longitude;

  public endSlot;

  public customerQuestions;

  public address1;

  public wasteLimitAnswers: any[];

  public extra;

  public isRestrictedBooking;

  public isRestrictedAsbestosBooking;

  public restrictedAsbestosKey;

  public isCommercialBooking;

  notify: Subject<boolean> = new Subject<boolean>();
  onNotify(event) {
    this.notify.next(true);
  }

  mapUpdate: Subject<boolean> = new Subject<boolean>();
  onmapUpdate(arg: any) {
    this.mapUpdate.next(arg);
  }

}
